import React, { useMemo, useState } from "react";

import { useLocals } from "../../../../hooks/useApi";
import useGeoLocation from "../../../../hooks/useGeoLocation";

import Loader from "../../../basic/Loader";
import Button from "../../../basic/Button";
import DevPre from "../../../dev/DevPre";

import SingleLocal from "./SingleLocal";

import "./../styles/sonicboxes.scss";
import MapLoadingPNG from "../../../../images/map_loading.png";
import { T } from "../../../../hooks/useLanguage";
import { Link } from "@reach/router";

export default function Locales({ onEnter, buttonLabel }) {
  const [offset, setOffset] = useState(0);

  const {
    isLoading: loadingLocation,
    error: errorLocation,
    lat,
    lng,
  } = useGeoLocation();

  const { data: locals, isLoading, isError, error } = useLocals(lat, lng, "");

  const filteredLocals = useMemo(() => {
    // console.log("locals", locals);
    if (!Array.isArray(locals)) {
      return [];
    }
    return locals.filter((local, index) => index < offset + 1);
  }, [locals, offset]);

  return (
    <>
      {isError ? (
        <DevPre>errorFetching {JSON.stringify(error, null, " ")}</DevPre>
      ) : null}
      {errorLocation !== null ? (
        <DevPre>
          errorLocation {JSON.stringify(errorLocation, null, " ")}
        </DevPre>
      ) : null}
      {loadingLocation ? (
        <>
          <p className="main-msg">
            <T>explorer.searchingnear</T>
          </p>
          <img
            src={MapLoadingPNG}
            alt={<T>loading</T>}
            className="map-loading"
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : errorLocation !== null ? (
            <p>
              <T>explorer.nolocation</T>
              <Link to="/sonicboxes?s=" className="primary">
                <T>search</T>
              </Link>
            </p>
          ) : isError ? (
            <p>
              <T>explorer.error</T>
            </p>
          ) : Array.isArray(locals) ? (
            <>
              <p className="main-msg">
                {locals.length === 0 ? (
                  <T>explorer.searchnearempty</T>
                ) : (
                  <T name={locals[0].name}>explorer.areyouin</T>
                )}
              </p>
              <ul className="locals-container">
                {filteredLocals.map((local, index) => (
                  <SingleLocal
                    key={index.toString()}
                    {...local}
                    onClick={onEnter}
                    label={
                      typeof buttonLabel === "string" ? (
                        buttonLabel
                      ) : typeof buttonLabel === "function" ? (
                        buttonLabel(local)
                      ) : (
                        <T>explorer.getin</T>
                      )
                    }
                  />
                ))}
              </ul>
              {locals.length > offset ? (
                <p>
                  <T>explorer.notexpectedresult</T>{" "}
                  <Button
                    onClick={() => setOffset(offset + 3)}
                    as="link"
                    className="brand"
                  >
                    <T>viewmore</T>
                  </Button>
                </p>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

import { useEffect, useState, createContext, useContext } from "react";

const GeoLocationContext = createContext({
  isLoading: true,
  error: null,
  lat: null,
  lng: null,
});

export const GeoLocationProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      console.log("Geolocation Available");
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
          setIsLoading(false);
          setError(null);
        },
        (geoPositionError) => {
          alert(
            "No hemos podido obtener tu ubicación, por favor habilita la geolocalización, para encontrar las Sonicboxes cercanas"
          );
          setIsLoading(false);
          setError(geoPositionError.message);
          console.warn("geoPositionError", geoPositionError.message);
        }
      );
    } else {
      console.log("Geolocation Not Available");
    }
  }, []);

  return (
    <GeoLocationContext.Provider
      value={{
        isLoading: isLoading,
        error: error,
        lat: lat,
        lng: lng,
      }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};

const useGeoLocation = () => useContext(GeoLocationContext);

export default useGeoLocation;

import axios from "axios";
import { useCallback } from "react";
import { useState } from "react";

export default function useUpload() {
  const [progress, setProgress] = useState(0);

  const uploadCallback = useCallback(function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    console.log(percentCompleted);
    setProgress(percentCompleted);
  }, []);

  const upload = (url, file) => {
    const config = {
      onUploadProgress: uploadCallback,
    };
    let data = new FormData();
    data.append(file.key, file.file);

    return axios.post(url, data, config);
  };
  return { progress: progress, upload: upload };
}

import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { createContext } from "react";

import ChevronUp from "../images/chevron-up-brand.png";
import ChevronUp_falabella from "../images/falabella/chevron-up-brand.png";

const DEFAULT_ASSETS = {
  ChevronUp,
};

const ThemeContext = createContext({
  name: "default",
  setTheme: () => {},
  assets: {},
  colors: {},
});

export const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState("default");

  useEffect(() => {
    document.body.className = "";
    switch (themeName) {
      case "falabella":
        console.log("is falabella!!!");
        if (document) {
          document.body.classList.add("falabella");
          console.log("add class falabella");
        }
        break;
      case "casper":
        console.log("is casper!!!");
        if (document) {
          document.body.classList.add("casper");
          console.log("add class casper");
        }
        break;
      default:
        if (document) {
          console.log("remove body classes");
        }
        break;
    }
  }, [themeName]);

  const assets = useMemo(() => {
    switch (themeName) {
      case "falabella":
        return {
          ChevronUp: ChevronUp_falabella,
        };
      default:
        return DEFAULT_ASSETS;
    }
  }, [themeName]);

  const colors = useMemo(() => {
    switch (themeName) {
      default:
        return {};
    }
  }, [themeName]);

  return (
    <ThemeContext.Provider
      value={{ name: themeName, assets, colors, setTheme: setThemeName }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return theme;
};

export default useTheme;

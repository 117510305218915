import React from "react";
import { useFavorites, useLocal } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import DevPre from "../../dev/DevPre";
import Song from "../sonicbox/components/Song";
import AccountHeader from "./components/AccountHeader";
import "./styles/favorites.scss";

const Favorites = () => {
  const { data, isLoading, isError, error } = useFavorites();
  const { data: radioData } = useLocal();

  return (
    <>
      <AccountHeader back="/menu" title="Mis canciones" />
      <div className="favorites">
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <DevPre>{JSON.stringify(error, null, " ")}</DevPre>
        ) : (
          <div className="songs favorites">
            {data.map((song) => (
              <Song
                key={song.songid}
                {...song}
                radioid={radioData?.radioid || null}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Favorites;

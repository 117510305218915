import React, { useEffect, useMemo } from "react";
import { useNavigate } from "@reach/router";

import { useUser } from "../../../hooks/useApi";

import DevPre from "../../dev/DevPre";

import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";

import { T } from "../../../hooks/useLanguage";

import "./styles/style.scss";
import LoginButtons from "../login/components/LoginButtons";

export default function Home() {
  const navigate = useNavigate();
  const { logged, loginRedirect } = useUser();

  useEffect(() => {
    if (logged) {
      if (!loginRedirect || loginRedirect === "/") {
        navigate("/sonicboxes");
      } else {
        navigate(loginRedirect);
      }
    }
  }, [logged, loginRedirect, navigate]);

  const showWelcome = useMemo(() => {
    const radioNameseo =
      loginRedirect.indexOf("/sonicbox/") === 0
        ? loginRedirect.replace("/sonicbox/", "")
        : null;
    if (!radioNameseo) {
      return true;
    }
    return !["bbc-music-pubs", "casper-radio"].includes(radioNameseo);
  }, [loginRedirect]);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      {showWelcome && (
        <>
          <p>
            <T>home.welcome</T>
          </p>
          <h1>
            <T>home.title</T>
          </h1>
        </>
      )}
      <LoginButtons />
    </div>
  );
}

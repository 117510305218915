import React from "react";
import Button from "../../../basic/Button";
import "../styles/single-local.scss";
import { setCookie } from "../../../../helpers/cookies";

const SingleLocal = ({
  name,
  nameseo,
  radioid,
  radioimage,
  onClick,
  label,
}) => {
  const handleOnClick = () => {
    setCookie(nameseo, nameseo, 1);
    onClick(nameseo, radioid);
  };

  const transformedName = name
    ?.replace("Cuatro Latas", "4 Latas")
    .replace("Terragona", "Tarragona");

  return (
    <li className="single-local">
      <Button onClick={handleOnClick}>
        <img src={radioimage} alt={`logo ${name}`} className="local-logo" />
        <h3>{transformedName}</h3>
      </Button>
    </li>
  );
};

export default SingleLocal;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../hooks/useApi";
import { useTerms } from "../hooks/useTerms";
import Loader from "./basic/Loader";
import { T } from "../hooks/useLanguage";

import "../styles/terms.scss";
import TermsFormGroup from "./TermsFormGroup";
import ClientTerms from "./clientsContent";
import DevPre from "./dev/DevPre";

export default function TermsAndConditionsContent({
  nameseo,
  isPeeking = false,
  ticked = false,
  onAccept = () => {},
  style = {},
}) {
  const { token } = useUser();
  const {
    refetch,
    data,
    isLoading,
    acceptTerms,
    radioData,
    form,
    contentFormKey,
    termsNameseo,
  } = useTerms(nameseo);
  const [checked, setChecked] = useState({});

  const handleCheck = useCallback((event) => {
    console.log(event.target.name, event.target.checked, event.target.type);
    let validInput = null;
    switch (event.target.type) {
      case "date":
        const date = new Date(event.target.value);
        const _18YearsAgo = new Date();
        _18YearsAgo.setFullYear(_18YearsAgo.getFullYear() - 18);
        validInput =
          date <= _18YearsAgo
            ? { name: event.target.name, value: event.target.value }
            : null;
        console.log("18 years ago", _18YearsAgo, date, validInput);
        break;
      case "text":
        validInput =
          typeof event.target.value === "string" &&
          event.target.value.length > 0
            ? { name: event.target.name, value: event.target.value }
            : null;
        console.log(
          "text",
          typeof event.target.value === "string",
          event.target.value.length > 0
        );
        break;
      case "radio":
      case "checkbox":
        validInput = event.target.checked
          ? { name: event.target.name, value: true }
          : null;
        break;
      default:
        break;
    }

    if (validInput) {
      setChecked((_checked) => ({
        ..._checked,
        [validInput.name]: validInput.value,
      }));
    } else {
      setChecked((_checked) => {
        if (_checked[event.target.name]) {
          delete _checked[event.target.name];
        }
        return _checked;
      });
    }
  }, []);

  useEffect(() => {
    if (!contentFormKey && form) {
      let acceptAll = true;
      form.forEach(({ input }) => {
        if (input?.required) {
          if (!Object.keys(checked).includes(input.name)) {
            acceptAll = false;
          }
        }
      });
      console.log("acceptAll from", acceptAll, checked, form);
      onAccept(acceptAll);
    }
  }, [contentFormKey, checked, form, onAccept]);

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      refetch();
    }
  }, [acceptTerms.isSuccess, refetch]);

  useEffect(() => {
    console.log("useEffect checked", checked);
    if (!token && nameseo && nameseo !== "") {
      console.log("checked", checked);
      let terms = JSON.parse(window.localStorage.getItem(`pendingterms`));
      if (terms) {
        terms[nameseo] = checked;
      } else {
        terms = { [nameseo]: checked };
      }
      window.localStorage.setItem(`pendingterms`, JSON.stringify(terms));
    }
  }, [checked, token, nameseo]);

  if (isLoading) return <Loader />;
  if (contentFormKey)
    return (
      <ClientTerms
        contentKey={contentFormKey}
        setChecked={setChecked}
        onAccept={onAccept}
        isPeeking={isPeeking}
        style={style}
      />
    );
  if (data) {
    return (
      <>
        {form ? (
          <div className="terms-container" style={style}>
            <h3>{radioData?.name}</h3>
            {form.map((formGroup, index) => (
              <TermsFormGroup
                key={index}
                onChange={handleCheck}
                {...formGroup}
                isPeeking={isPeeking}
              />
            ))}
          </div>
        ) : (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: data.text }}
              className="terms-container"
              style={style}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="checkbox"
                checked={ticked}
                onChange={(event) => {
                  onAccept(!!event.target.checked);
                }}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  backgroundColor: ticked ? "green" : "red",
                }}
                disabled={!!form}
                id="terms-checkbox"
              />
              <label htmlFor="terms-checkbox">
                {ticked ? <T>accepted</T> : <T>accept</T>}
              </label>
            </div>
          </>
        )}
      </>
    );
  }
  return (
    <div style={{ zIndex: 999 }}>
      <DevPre>
        {typeof contentFormKey}{" "}
        {contentFormKey === null ? "null" : JSON.stringify(contentFormKey)}
        {"\n"}
        {typeof termsNameseo}{" "}
        {termsNameseo === null ? "null" : JSON.stringify(termsNameseo)}
      </DevPre>
    </div>
  );
}

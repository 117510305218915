import React from "react";
import PropTypes from "prop-types";
import { Link } from "@reach/router";

import TabBar from "./TabBar";
import { ScrollProvider } from "../../hooks/useScrollObserver";
import { ApiProvider, InformProvider } from "../../hooks/useApi";
import StoredCodesManager from "../StoredCodesManager";

import { ModalsProvider } from "../../hooks/useModal";
import modals from "../modals";
import { AdsProvider } from "../../hooks/useAds";
import { ThemeProvider } from "../../hooks/useTheme";
import { T } from "../../hooks/useLanguage";
import { PollProvider } from "../../hooks/usePoll";
import PriceCodesTag from "../PriceCodesTag";

const Layout = ({ children }) => {
  return (
    <ApiProvider>
      <ThemeProvider>
        <AdsProvider>
          <InformProvider>
            <PollProvider>
              <ModalsProvider modals={modals}>
                <StoredCodesManager />
                <ScrollProvider className="layout">
                  <PriceCodesTag />
                  <TabBar />
                  <main>{children}</main>
                  <footer
                    className="container"
                    style={{
                      marginTop: `2rem`,
                      display: "flex",
                      alignItems: "baseline",
                      gap: 8,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link to="/privacy" style={{ fontSize: "inherit" }}>
                      <T>home.privacy</T>
                    </Link>{" "}
                    {`© ${new Date().getFullYear()}, Built by `}
                    <a href="https://contacto.millsonic.com/?utm_source=millsonicbox&utm_medium=web">
                      Millsonic
                    </a>
                    <span>{` v${process.env.REACT_APP_VERSION}`}</span>
                  </footer>
                </ScrollProvider>
              </ModalsProvider>
            </PollProvider>
          </InformProvider>
        </AdsProvider>
      </ThemeProvider>
    </ApiProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import { Fragment } from "react";

export default function ParqueAraucoTermsText() {
  return (
    <Fragment>
      <h1>Autorización para el tratamiento de datos personales clientes</h1>
      <div
        style={{
          maxHeight: 300,
          overflow: "auto",
          marginBottom: 20,
          padding: 20,
        }}
      >
        <p style={{ textAlign: "justify" }}>
          Por medio del presente documento autorizo de manera voluntaria,
          previa, expresa, informada e inequívoca a PARQUE ARAUCO COLOMBIA
          S.A.S., identificada con Nit 900.252.139-0, en adelante PACSAS, para
          que recolecte, almacene, circule, suprima, comparta, actualice,
          transmita, transfiera y use mis datos personales (incluyendo mis
          imágenes, videos, gustos, preferencias personales, localización
          geográfica, información de consumo, entre otras), para:
          <br />
          *Acceder a los servicios de los Centros Comerciales ubicados en
          Colombia 1 , que hagan parte o sean operados por Parque Arauco 2 .
          *Participar en actividades de mercadeo y/o promocionales, eventos,
          actividades recreativas, concursos, juegos, promociones, rifas,
          sorteos y similares realizados en los Centros Comerciales, incluyendo
          su realización y/o comunicación a través de redes sociales, medios
          digitales o cualquier otro medio que se estime pertinente, existentes
          o por existir. * Utilizar los datos personales con fines comerciales y
          de mercadeo, análisis estadístico, investigaciones de mercado,
          segmentación de audiencias y envío de comunicación dirigida. *Enviar y
          recibir información de las actividades de mercadeo y/o promocionales,
          información sobre eventos, actividades lúdicas, culturales o de
          entretenimiento, concursos o similares, publicidad, ofertas de las
          marcas que hacen parte de los Centros Comerciales, de Parque Arauco y
          demás proyectos, servicios y productos que este ofrece y desarrolla,
          vía email, telefónica, SMS, física, redes sociales, medios digitales,
          o cualquier otro medio que se estime conveniente. * Dar acceso a los
          distintos servicios que ofrece Parque Arauco a través de sus sitios
          web, incluyendo descargas de contenidos y formatos. *Actualizar y
          complementar los datos personales con otras bases de datos (incluso
          manejadas por terceros, de conformidad con la normativa aplicable) con
          el fin de mantener actualizada la información y preferencias de los
          clientes. *Recolectar los datos personales de forma automática a
          través del uso de aplicaciones móviles, software, email, red wifi,
          cookies en los sitios web de Parque Arauco, web beacons u otras
          tecnologías, encuestas, focus groups u otros métodos de investigación
          de mercado, las cuales serán infirmada a los titulares de acuerdo a la
          normatividad aplicable. * Usar los datos recolectados para
          proporcionar contenido personalizado y recomendaciones basadas en sus
          preferencias, entender cómo se utilizan los servicios de Parque
          Arauco, mejorarlos y desarrollar nuevos productos y servicios.
          *Evaluar la calidad del servicio, realizar estudios de mercado y
          análisis estadísticos para usos internos realizados por Parque Arauco,
          así como llevar a cabo la realización de encuestas y sondeos generales
          que tengan por objeto determinar opiniones sobre un bien o servicio,
          control de calidad y de servicio al cliente (vía email, telefónica,
          SMS, física o cualquier otro medio). * Facilitar el diseño e
          implementación de programas de fidelización, CRM y el reconocimiento o
          entrega de los beneficios del mismo. *Controlar el acceso a los
          Centros Comerciales y/o a Parque Arauco, así como establecer medidas
          de seguridad, incluyendo el establecimiento de zonas de video
          vigilancia. * Llevar un adecuado registro y control de los usuarios
          del servicio de coches para niños, sillas de ruedas, scooters, coches
          de mascotas o cualquier otro elemento similar (“Rodados”), contactar
          al usuario y adelantar todas las actividades necesarias para la
          adecuada prestación del servicio de Rodados y el cumplimiento de las
          obligaciones derivados del mismo. *Efectuar procedimientos y consultas
          para el conocimiento del cliente o visitante y efectuar verificación
          SAGRILAFT y/o PTEE, cuando sea necesario de acuerdo con la
          normatividad vigente. * Atender, dar respuesta y llevar el control de
          las PQRF o hurtos, perdidas, accidentes y similares (“los Incidentes”)
          que se presenten en los Centros Comerciales y/o Parque Arauco;
          contactarme y/o gestionar bienes y servicios proveídos por terceros,
          cuando sea necesario para la adecuada atención de PQRF e Incidentes
          y/o dar traslado a los encargados de solucionarlas; colaborar con las
          autoridades, aseguradoras y demás entidades pertinentes, cuando sea
          necesario ante un requerimiento, PQRF o Incidente; el manejo interno
          y/o cumplimiento de obligaciones derivadas de las actividades de
          vigilancia, control y seguridad. *Permitir el acceso a la información
          y datos personales a los auditores o terceros contratados por Parque
          Arauco para llevar a cabo procesos de auditoría interna o externa
          propios de la actividad comercial que se desarrolla Parque Arauco.
          *Incluir mis Datos Personales en las bases de datos. Transmitir o
          trasferir (en cumplimiento de la normatividad vigente) mis Datos
          Personales a terceros, ubicados en Colombia o en el exterior, para la
          ejecución o cumplimiento de las finalidades descritas en este
          documento, en especial, atención de PQR e incidentes, el
          almacenamiento, análisis estadístico, estudios de mercado,
          segmentación de audiencias, envío de contenidos personalizados o
          dirigidos, procesamiento de la información y datos personales,
          realización de encuestas y sondeos generales, entre otros, o cuando
          ello sea necesario para el cumplimiento de las obligaciones legales,
          contractuales y el desarrollo de las operaciones de Parque Arauco.
          *Transferir o transmitir mis Datos Personales a distintas áreas de
          Parque Arauco y a sus empresas vinculadas, en Colombia o en el
          exterior, cuando ello sea necesario para el cumplimiento de
          obligaciones legales, contractuales, para el desarrollo de sus
          operaciones y/o el cumplimiento y ejecución de estas finalidades. El
          almacenamiento puede llevarse a cabo en servidores ubicados en
          terceros países, lo que a su vez puede conllevar a la transmisión o
          trasferencia internacional de los datos personales a cualquier país,
          incluyendo países con un nivel de protección de datos diferentes a los
          del país donde los datos son recolectados, razón por la cual se
          autoriza expresamente para efectuar dicha transmisión o transferencia
          (según aplique). *Cualquier otra actividad de naturaleza similar y/o
          complementaria a las anteriormente descritas que sean necesarias para
          la prestación de servicios por parte Parque Arauco, siempre y cuando
          sean consistentes o compatibles (no sorpresivas o inconexas) con las
          finalidades principales aquí indicadas. * Reportar los datos
          personales a las autoridades competentes que así lo requieran * El
          cumplimiento de obligaciones legales o contractuales, para el
          desarrollo de las operaciones de PACSAS y/o el cumplimiento y
          ejecución de estas finalidades
        </p>
        <p style={{ textAlign: "justify" }}>
          Asimismo, manifiesto que he sido informado por PACSAS de lo siguiente:
          <br />
          1. Que son datos sensibles aquellos que afectan la intimidad del
          titular o cuyo uso indebido puede generar discriminación.
          <br />
          2. Que es de carácter facultativo responder preguntas que versen sobre
          datos personales sensibles (como imágenes, datos biométricos, etc.) y
          de menores (en caso de ser aplicable). Además, he sido informado que
          como titular de mis datos personales sensibles no estoy obligado a
          autorizar su tratamiento y que PACSAS no condicionará la venta de sus
          productos y/o la prestación de sus servicios a la autorización del
          tratamiento de datos sensibles. En consecuencia, autorizo a PACSAS a
          realizar el tratamiento de mis datos personales sensibles de
          conformidad con las finalidades arriba indicadas.
          <br /> 3. Que mis derechos como titular son los previstos en la
          Constitución, en la Ley 1581 de 2012 y sus normas reglamentarias, y
          especialmente los siguientes: a) Acceder en forma gratuita a los datos
          proporcionados que hayan sido objeto de tratamiento. b) Solicitar la
          actualización y rectificación de su información frente a datos
          parciales, inexactos, incompletos, fraccionados, que induzcan a error,
          o a aquellos cuyo tratamiento esté prohibido o no haya sido
          autorizado. c) Solicitar prueba de la autorización otorgada. d)
          Presentar ante la Superintendencia de Industria y Comercio (SIC)
          quejas por infracciones a lo dispuesto en la normatividad vigente. e)
          Revocar la autorización y/o solicitar la supresión del dato, a menos
          que exista un deber legal o contractual que haga imperativo conservar
          la información. f) Abstenerse de responder las preguntas sobre datos
          sensibles o sobre datos de las niñas y niños y adolescentes. Estos
          derechos los podré ejercer siguiendo los procedimientos previstos en
          la Política de Tratamiento de la Información de PACSAS, publicada en
          cualquier de las páginas web de los Centros Comerciales{" "}
          <a href="www.parquelacolina.com" target="_blank" rel="noreferrer">
            www.parquelacolina.com
          </a>
          ,{" "}
          <a
            href="www.premiumoutletarauco.com"
            target="_blank"
            rel="noreferrer"
          >
            www.premiumoutletarauco.com
          </a>
          ,{" "}
          <a
            href="www.parquearboleda.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#00b7ab" }}
          >
            www.parquearboleda.com
          </a>
          ,{" "}
          <a
            href="www.parquecaracolí.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#00b7ab" }}
          >
            www.parquecaracolí.com
          </a>
          ,{" "}
          <a
            href="www.parquefabricato.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#00b7ab" }}
          >
            www.parquefabricato.com
          </a>
          ,{" "}
          <a
            href="www.parquealegra.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#00b7ab" }}
          >
            www.parquealegra.com
          </a>
          <br />
          4. Que en caso de tener alguna consulta o reclamo podré contactar a
          PACSAS en el correo electrónico{" "}
          <a
            href="mailto:quejasyreclamosdp@parauco.com"
            target="_blank"
            rel="noreferrer"
            style={{ color: "#00b7ab" }}
          >
            quejasyreclamosdp@parauco.com
          </a>
        </p>
      </div>
    </Fragment>
  );
}

export default function interpolate(value, rangeIn, rangeOut, clamp = true) {
  const [minIn, maxIn] = rangeIn;
  const [minOut, maxOut] = rangeOut;
  const ratio = (value - minIn) / (maxIn - minIn);
  console.log("ratio", ratio);
  const result = ratio * (maxOut - minOut) + minOut;
  console.log("result", result);
  if (clamp) {
    if (minOut < maxOut) {
      return Math.min(Math.max(result, minOut), maxOut);
    } else {
      return Math.max(Math.min(result, minOut), maxOut);
    }
  }
  return result;
}

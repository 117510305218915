import React, { useState } from "react";
import { useMemo } from "react";
import { useUser } from "../../../hooks/useApi";
import Button from "../../basic/Button";
import InputText from "../../basic/InputText";
import AccountHeader from "./components/AccountHeader";
import ProfileHeader from "./components/ProfileHeader";
import IconEdit from "../../../images/icon-edit-brand.png";
import Loader from "../../basic/Loader";

const Profile = () => {
  const { userQuery, user, editUser, editUserMutation } = useUser();
  const [editing, setEditing] = useState(false);

  const { name, country, city, phone } =
    user !== null
      ? user
      : {
          name: "",
          email: "",
          avatar: null,
          country: "",
          city: "",
          phone: "",
        };

  const [userState, setUserState] = useState({ ...user });

  const handleChangeUser = (value, name) => {
    const newState = { ...userState };
    newState[name] = value;
    setUserState(newState);
  };

  const hasChanges = useMemo(
    () =>
      userState.name !== name ||
      userState.country !== country ||
      userState.city !== city ||
      userState.phone !== phone,
    [name, country, city, phone, userState]
  );

  const handelRestore = () => {
    setUserState({ ...user });
    setEditing(false);
  };

  const handleSaveChanges = () => {
    editUser(userState);
    setEditing(false);
  };

  return (
    <>
      <AccountHeader back="/menu" />
      <div className="profile">
        <ProfileHeader />
        {userQuery.isLoading ? null : userQuery.isError ? null : (
          <>
            <form className={editing ? "editing" : ""}>
              <Button
                as="transparent"
                className="btn-edit"
                onClick={() => setEditing(true)}
              >
                <img src={IconEdit} alt="Edit" />
              </Button>
              <InputText
                label="Nombre"
                value={userState?.name || ""}
                onChange={handleChangeUser}
                name={"name"}
                readOnly={!editing}
              />
              <InputText
                label="Pais"
                value={userState?.country || ""}
                onChange={handleChangeUser}
                name={"country"}
                readOnly={!editing}
              />
              <InputText
                label="Ciudad"
                value={userState?.city || ""}
                onChange={handleChangeUser}
                name={"city"}
                readOnly={!editing}
              />
              <InputText
                label="Teléfono"
                value={userState?.phone || ""}
                onChange={handleChangeUser}
                name={"phone"}
                readOnly={!editing}
              />
            </form>
            {editing && (
              <div className="buttons-container">
                <Button
                  onClick={handleSaveChanges}
                  className="btn-save brand"
                  disabled={!hasChanges}
                >
                  {editUserMutation.isLoading ? <Loader /> : "Guardar cambios"}
                </Button>
                <Button
                  onClick={handelRestore}
                  className="btn-undo bordered brand"
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Profile;

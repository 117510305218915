import { useNavigate } from "@reach/router";
import backArrow from "../../../../images/chevron-left-white.png";
import "../styles/account-header.scss";

export default function AccountHeader({ back, title = null }) {
  const navigate = useNavigate();
  return (
    <div className="account-header">
      <button
        type="button"
        className="back-button"
        onClick={() => {
          navigate(back);
        }}
      >
        <img src={backArrow} alt="back" className="icon" />
      </button>
      {title && <h1>{title}</h1>}
    </div>
  );
}

import { useState } from "react";
import Button from "../../basic/Button";
import TaskHeader from "./components/TaskHeader";
import "./styles/tasks.scss";
import { useTasks } from "../../../hooks/useTasks";
import Loader from "../../basic/Loader";
import { useEffect } from "react";
import { useCallback } from "react";
import TaskSwitcher from "./TaskSwitcher";
import { reactifyBR } from "../../../utils/stringUtils";

const STEP_INIT = 0;
const STEP_1 = 1;
const STEP_2 = 2;
const STEP_END = STEP_2;

export default function Task({ name, radioid, nameseo, radioimage }) {
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [failed, setFailed] = useState(false);
  const {
    tasks,
    isLoading,
    isError,
    error,
    submitTask,
    submitLoading,
    submitIsError,
    submitError,
    submitData,
    currentTask,
    setCurrentTask,
  } = useTasks(nameseo);
  const [noReturn, setNoReturn] = useState(false);

  useEffect(() => {
    // console.log("typeof document", typeof document);
    if (typeof document !== "undefined") {
      document.body.classList.add(`task-${nameseo}`);
    }

    return () => {
      if (typeof document !== "undefined") {
        document.body.classList.remove(`task-${nameseo}`);
      }
    };
  }, [nameseo]);

  useEffect(() => {
    if (submitData?.status === "success") {
      setNoReturn(true);
    }
  }, [submitData]);

  const handlePrevStep = () => {
    setStep(Math.max(STEP_INIT, step - 1));
  };

  const handleNextStep = () => {
    if (step === STEP_INIT) {
      if (tasks.tasks.length === 1) {
        // console.log("setCurrentTask", tasks.tasks[0]);
        setCurrentTask(tasks.tasks[0]);
      }
    }
    if (step === 1) {
      // console.log("submitTask", values);
      // console.log("task", currentTask);
      // console.log("tasks", tasks);
      if (currentTask?.checkcorrect) {
        const failed = checkFailed(currentTask.checkcorrect, values);
        setFailed(failed);
      }
      submitTask({
        radioid: radioid,
        data: JSON.stringify(values),
      });
    }
    setStep(Math.min(STEP_END, step + 1));
  };

  const handleRestart = () => {
    setStep(STEP_INIT);
    setValues({});
    setIsValid(false);
    setFailed(false);
  };

  const checkFailed = (correct, values) => {
    let failed = false;
    Object.keys(correct).forEach((key) => {
      // console.log(correct[key], values[key]);
      if (correct[key] !== values[key]) {
        failed = true;
      }
    });
    return failed;
  };

  const handleLastStep = useCallback(() => {
    // console.log("last step");
    if (submitData?.data?.url) {
      window.location.href = submitData?.data?.url;
    } else {
      window.location.href = "/sonicboxes";
    }
  }, [submitData]);

  return (
    <>
      <TaskHeader
        name={name}
        radioimage={radioimage}
        large={step === STEP_INIT}
      />
      {(isError && <p className="alert alert-warning">{error.message}</p>) ||
        (!isLoading && !tasks && (
          <>
            <p className="alert alert-warning">No task found</p>
            <p>{nameseo}</p>
          </>
        )) ||
        (step === STEP_INIT && (
          <>
            <h2>¡Bienvenido!</h2>
            {isLoading && <Loader />}
            <p>{tasks?.message}</p>
          </>
        )) ||
        (step === STEP_1 && (
          <TaskSwitcher
            type={currentTask?.type}
            task={currentTask}
            values={values}
            setValues={setValues}
            setIsValid={setIsValid}
            onSuccess={handleNextStep}
          />
        )) ||
        (step === STEP_2 && null) || (
          <div>
            <h2>
              {tasks?.successtitle
                ? tasks.successtitle
                : "¡Gracias por tu opinión!"}
            </h2>
            {submitLoading && <Loader />}
            {submitIsError && (
              <div className="alert alert-danger">
                {submitError?.message || "Ocurrió un error"}
              </div>
            )}
            {!submitLoading && !submitIsError && submitData?.data?.qr && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p style={{ marginTop: 0 }}>
                  {tasks?.failmessage && failed
                    ? reactifyBR(tasks.failmessage)
                    : reactifyBR(tasks?.successmessage)}
                </p>
                <img
                  src={
                    tasks?.substituteqr
                      ? tasks.substituteqr
                      : submitData.data.qr
                  }
                  alt="QR"
                  style={{ width: 200, marginBottom: 20, borderRadius: 10 }}
                />
                <Button className={"success accent"} onClick={handleLastStep}>
                  {tasks?.redeemtext ? tasks.redeemtext : "Canjear créditos"}
                </Button>
              </div>
            )}
          </div>
        )}
      {(step === STEP_INIT && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 300,
            margin: "10px auto 0",
          }}
        >
          {tasks?.tasks.length > 1 &&
            tasks?.tasks.map((task, index) => (
              <Button
                key={index}
                className="success"
                onClick={() => {
                  setCurrentTask(task);
                  handleNextStep();
                }}
                style={{ marginBottom: 10 }}
              >
                {task.title}
              </Button>
            ))}
        </div>
      )) ||
        null}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        {step !== STEP_INIT && !noReturn && (
          <Button
            className={"accent"}
            as="transparent"
            onClick={handlePrevStep}
            style={{ marginRight: 20 }}
          >
            Volver
          </Button>
        )}
        {step === STEP_END && (
          <Button className={"accent"} as="transparent" onClick={handleRestart}>
            Volver a empezar
          </Button>
        )}
        {!isLoading &&
          !(step !== STEP_1 && !!tasks?.tasks && tasks?.tasks.length > 1) &&
          !!tasks &&
          ((step !== STEP_END && (
            <Button
              className={"success"}
              onClick={handleNextStep}
              disabled={step === STEP_1 && !isValid}
            >
              Continuar
            </Button>
          )) || (
            <Button className={"success"} onClick={handleLastStep}>
              Finalizar
            </Button>
          ))}
        {isError && <p className="alert alert-warning">{error.message}</p>}
      </div>
      {!!tasks && (
        <p>
          {step + 1} de {STEP_END + 1}
        </p>
      )}
    </>
  );
}

import { useCallback, useState, useEffect } from "react";
import { useUser } from "../hooks/useApi";
import { useInform } from "../hooks/useApi";
import { getNameseoByRadioid } from "../hooks/useApi";
import { useCode } from "../hooks/useCode";
import { useModal } from "../hooks/useModal";
import { ADD_CREDITS_SUCCESS_MODAL } from "../constants";
import decryptCode from "../utils/decryptCode";

export default function StoredCodesManager() {
  const isBrowser = useCallback(() => typeof window !== "undefined", []);
  const { logged, updateUser } = useUser();
  const { sendCode } = useCode();
  const [sentCode, setSentCode] = useState(null);
  const { sendScan } = useInform();
  const { stackModal } = useModal(ADD_CREDITS_SUCCESS_MODAL);

  const removeStoredCode = useCallback(
    (storedCode) => {
      const currStored =
        isBrowser() && window.localStorage.getItem("sonicbox-codes")
          ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
          : [];
      console.log("currStored", currStored, storedCode);
      const storedCodeIndex = currStored.indexOf(storedCode);
      console.log(storedCodeIndex);
      if (storedCodeIndex > -1) {
        currStored.splice(storedCodeIndex, 1);
        if (isBrowser) {
          window.localStorage.setItem(
            "sonicbox-codes",
            JSON.stringify(currStored)
          );
        }
        setSentCode(null);
      }
    },
    [isBrowser]
  );

  useEffect(() => {
    const addCode = (code) => {
      const storedCodes =
        isBrowser() && window.localStorage.getItem("sonicbox-codes")
          ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
          : [];
      storedCodes.push(code);
      if (isBrowser()) {
        window.localStorage.setItem(
          "sonicbox-codes",
          JSON.stringify(storedCodes)
        );
      }
    };

    async function validateStoredCodes() {
      const storedCodes =
        isBrowser() && window.localStorage.getItem("sonicbox-codes")
          ? JSON.parse(window.localStorage.getItem("sonicbox-codes"))
          : [];
      if (logged && storedCodes.length > 0) {
        console.log("useEffect in useCode", logged, storedCodes);
        const firstCode = storedCodes[0];
        removeStoredCode(firstCode);
        console.log(storedCodes);
        const splitted = decryptCode(firstCode).split("-");
        if (splitted.length > 0) {
          const codeToSend = splitted[0];
          const radioid = splitted[1];
          const nameseo = await getNameseoByRadioid(radioid);
          if (codeToSend.length > 0 && sentCode !== codeToSend) {
            setSentCode(codeToSend);
            sendCode(
              { ccode: codeToSend },
              (response) => {
                updateUser();
                console.log("response", response);
                if (
                  response.hasOwnProperty("status") &&
                  response.status === "success"
                ) {
                  setSentCode(null);
                  sendScan(response?.added, radioid, nameseo);
                  stackModal({ credits: response?.added });
                }
              },
              (error) => {
                console.log("sendcode error", error);
                if (error.hasOwnProperty("response")) {
                  console.log("error response", error.response);
                  if (error.response?.data?.msg === "Code does not work") {
                    setSentCode(null);
                    alert("El código ya no es válido");
                  }
                } else {
                  addCode(firstCode);
                }
              }
            );
          }
        }
      }
    }
    validateStoredCodes();
  }, [
    logged,
    sendCode,
    sentCode,
    removeStoredCode,
    isBrowser,
    updateUser,
    sendScan,
    stackModal,
  ]);
  return null;
}

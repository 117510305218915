import { useEffect, useState } from "react";
import TaskFormInput from "./TaskFormInput";

export default function TaskForm({
  title,
  fields,
  values,
  setValues,
  setIsValid,
  onSuccess,
}) {
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleOnChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  // validate form
  useEffect(() => {
    const errors = {};
    fields.forEach((field) => {
      if (field.required && !values[field.name]) {
        errors[field.name] = field.error || "Campo requerido";
      }
    });
    setErrors(errors);
    setIsValid(Object.keys(errors).length === 0);
  }, [values, fields, setIsValid]);

  return (
    <div className="task-form">
      <h1>{title}</h1>
      {fields.map((field) => (
        <TaskFormInput
          key={field.name}
          field={field}
          value={
            typeof values[field.name] !== "undefined"
              ? values[field.name]
              : null
          }
          handleOnChange={handleOnChange}
          touched={
            typeof touched[field.name] !== "undefined" && touched[field.name]
          }
          error={errors[field.name]}
        />
      ))}
    </div>
  );
}

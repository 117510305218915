import React, { useEffect } from "react";
import { useUser } from "../hooks/useApi";
import { useNavigate } from "@reach/router";

const OnlyPublicRoute = ({ component: Component, location, ...rest }) => {
  const { logged } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("OnlyPublicRoute", location.pathname);
  }, [location, rest]);

  useEffect(() => {
    if (logged) {
      navigate("/");
    }
  }, [logged, navigate]);

  return <Component {...rest} />;
};

export default OnlyPublicRoute;

import { useRadioByNameseo } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import "./styles/tasks.scss";
import Task from "./Task";
import { TasksProvider } from "../../../hooks/useTasks";

export default function TaskContainer() {
  const { getRadioQuery } = useRadioByNameseo();
  const { isLoading, data } = getRadioQuery;

  return (
    <TasksProvider>
      <div className="tasks-container">
        {(isLoading && <Loader />) || <Task {...data?.data} />}
      </div>
    </TasksProvider>
  );
}

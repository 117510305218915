export default function SonicboxHeader({ name, radioimage }) {
  const transformedName = name
    ?.replace("Cuatro Latas", "4 Latas")
    .replace("Terragona", "Tarragona");

  return (
    <>
      <div className="sonicbox-header-wrapper">
        <div className="sonicbox-header">
          <img className="sonicbox-header-image" src={radioimage} alt={name} />
          <h1>{transformedName}</h1>
        </div>
      </div>
      <div className="sonicbox-header-placeholder" />
    </>
  );
}

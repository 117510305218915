import React, { useEffect, useMemo, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useState } from "react";
import { API_PROXY, useLocal, useRadioidByNameseo, useUser } from "./useApi";

export const useTerms = (parentNameseo) => {
  const { nameseo: localNameseo } = useLocal();
  const nameseo = parentNameseo ? parentNameseo : localNameseo;
  const { user } = useUser();
  const [form, setForm] = useState(null);
  const [contentFormKey, setContentFormKey] = useState(null);
  const { radioid, data: radioData } = useRadioidByNameseo(nameseo);

  useEffect(() => {
    console.log(
      "update useTerms from nameseo",
      nameseo,
      parentNameseo,
      localNameseo
    );
  }, [nameseo, parentNameseo, localNameseo]);

  const extraQuery = useQuery(
    ["extra", radioid, user?.userid],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_PROXY}getUserExtra?radioid=${radioid}&token=${user.token}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response?.data?.extra === "string") {
                    console.log(
                      "extra",
                      response.data.extra.replaceAll("'", '"')
                    );
                    response.data.extra = JSON.parse(
                      response.data.extra.replaceAll("'", '"')
                    );
                  }
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled: !!radioid && !!user?.token,
    }
  );

  const termsQuery = useQuery(
    ["terms", nameseo, user?.userid],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(`${API_PROXY}getTermsForm/${nameseo}`)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
                if (response.status === 204) {
                  onResolve({ text: "" });
                }
              }
              onReject("error getting terms");
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        console.log("termsQuery error", e);
        throw e;
      }
    },
    {
      enabled: !!nameseo,
    }
  );

  const acceptTerms = useMutation(
    async (extra) => {
      try {
        if (user?.userid === undefined || user?.userid === null) {
          throw new Error("user not logged");
        }
        if (radioid === undefined || radioid === null) {
          throw new Error("Radio not selected");
        }
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_PROXY}addUserExtra?${new URLSearchParams({
                radioid: radioid,
                token: user?.token,
                extra: JSON.stringify(extra),
              }).toString()}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      onSuccess: (result, variables, context) => {
        window.localStorage.removeItem(`terms-${nameseo}`);
      },
    }
  );

  useEffect(() => {
    if (termsQuery?.data?.text) {
      const dom = document.createElement("div");
      dom.innerHTML = termsQuery.data.text;
      const _form = dom.querySelector("form");
      const contentKeyDom = dom.querySelector("#content");
      console.log("termsQuery", dom, contentKeyDom, contentKeyDom?.textContent);
      if (contentKeyDom?.textContent) {
        console.log("contentKey", contentKeyDom.textContent);
        setContentFormKey(contentKeyDom.textContent);
        setForm(null);
      } else if (_form) {
        const formGroups = _form.querySelectorAll(".form-group");
        const inputs = [];
        formGroups.forEach((formGroup) => {
          const label = formGroup.querySelector("label");
          const input = formGroup.querySelector("input");
          const small = formGroup.querySelector("small");
          const link = label.querySelector("a");
          inputs.push({
            label,
            input,
            small,
            link,
          });
        });
        setContentFormKey(null);
        setForm(inputs);
      } else {
        setContentFormKey(null);
        setForm(null);
      }
    }
  }, [termsQuery?.data]);

  const links = useMemo(() => {
    if (form) {
      const links = [];
      form.forEach(({ link }) => {
        if (link) {
          links.push(link);
        }
      });
      return links;
    }
    return null;
  }, [form]);

  useEffect(() => {
    if (user?.token && acceptTerms.status === "idle" && radioid) {
      const locallyAccepted = window.localStorage.getItem(`terms-${nameseo}`);
      if (locallyAccepted === "accepted") {
        console.log("acceptTerms", "terms");
        acceptTerms.mutate("terms");
      }
    }
  }, [nameseo, acceptTerms, acceptTerms.status, user?.token, radioid]);

  const lastUpdate = useRef(0);

  const [userExtra, setUserExtra] = useState(undefined);
  useEffect(() => {
    console.log(
      "useMemo from extraQuery",
      extraQuery.dataUpdatedAt,
      extraQuery?.data?.data?.extra
    );
    if (extraQuery.dataUpdatedAt > lastUpdate.current) {
      lastUpdate.current = extraQuery.dataUpdatedAt;
      if (extraQuery?.data?.data?.extra) {
        try {
          const parsed = JSON.parse(extraQuery?.data?.data?.extra);
          console.log("extra parsed", parsed);
          setUserExtra(parsed);
        } catch (e) {
          console.log("error parsing extra", e);
          setUserExtra({});
        }
      } else {
        setUserExtra({});
      }
    } else {
      console.log("extraQuery not updated");
    }
  }, [extraQuery.dataUpdatedAt, extraQuery?.data?.data]);

  useEffect(() => {
    console.log("userExtra", userExtra);
  }, [userExtra]);

  const ready = useMemo(
    () =>
      termsQuery?.status === "success" &&
      extraQuery?.status === "success" &&
      userExtra !== undefined,
    [termsQuery?.status, extraQuery?.status, userExtra]
  );

  const termsRequired = useMemo(() => {
    if (ready) {
      return (
        termsQuery?.data?.text !== undefined && termsQuery?.data?.text !== ""
      );
    }
    return undefined;
  }, [ready, termsQuery?.data?.text]);

  return {
    ...termsQuery,
    acceptTerms: acceptTerms,
    radioid: radioid,
    radioData: radioData,
    form: form,
    contentFormKey: contentFormKey,
    links: links,
    extraQuery: extraQuery,
    userExtra: userExtra,
    ready: ready,
    termsNameseo: nameseo,
    termsRequired: termsRequired,
  };
};

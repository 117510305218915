import React, {  useRef } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-price.scss";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import {  VOTED_SUCCESS_MODAL } from "../../constants";
import useLanguage, { T } from "../../hooks/useLanguage";
import DefaultSongCover from "../basic/DefaultSongCover";
import { useNavigate } from "@reach/router";
import { padStringLeft } from "../../utils/decryptCode";

const PriceModal = ({
  songid,
  songname,
  artist,
  cover,
  credits,
  radioid,
  nameseo,
  msg,
  image,
  price,
}) => {
  const navigate = useNavigate();
  const { i18n, update } = useLanguage();
  const { openModal } = useModal(VOTED_SUCCESS_MODAL);
  const modalRef = useRef();

  const handleCloseModal = () => {
    openModal({
      credits: credits,
      songname: songname,
      radioid: radioid,
      nameseo: nameseo,
    });
  };

  return (
    <Modal className="modal-price" ref={(r) => (modalRef.current = r)}>
      <div className="modal-price-info">
        <img
          src={image}
          alt={msg}
          className="cover"
          altComponent={<DefaultSongCover />}
        />
        <div className="msg">{msg}</div>
        {price && (
          <div className="submsg">
            <T>votesuccess.priceinstructions</T>
          </div>
        )}
        <p className="date">
          {padStringLeft(new Date().getHours().toString(), 2, "0")}:
          {padStringLeft(new Date().getMinutes().toString(), 2, "0")}
        </p>
        <Button
          className="big gradient"
          onClick={() => {
            handleCloseModal();
          }}
        >
          <T>votesuccess.continue</T>
        </Button>
      </div>
    </Modal>
  );
};

export default PriceModal;

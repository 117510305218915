import { useEffect, useState } from "react";
import Loader from "../basic/Loader";
import Modal from "../layout/Modal";
import Button from "../basic/Button";
import "../../styles/help.scss";
import MusicHelp from "../../images/icon-music.png";
import useLanguage, { T } from "../../hooks/useLanguage";
import { useModal } from "../../hooks/useModal";
import InputCheckbox from "../basic/InputCheckbox";
import { useContact, useLocal, useUser } from "../../hooks/useApi";

export default function SuggestModal() {
  const { i18n } = useLanguage();
  const { closeModal } = useModal();
  const { user } = useUser();
  const {
    isLoading,
    mutate: submit,
    isSuccess,
    data: submitData,
    reset: resetSubmit,
  } = useContact();
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState(true);
  const { nameseo, radioid } = useLocal();

  useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
    }
  }, [user]);

  const handleSubmit = () => {
    submit({
      token: user?.token ? user.token : "",
      email,
      type: "help",
      msg: JSON.stringify({
        subject: `${nameseo}-${radioid}`,
        message,
        notifications,
      }),
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // console.log("submitData", submitData);
      if (submitData?.data?.data?.msg) {
        alert(submitData.data.data.msg);
      } else if (submitData?.data?.msg) {
        alert(submitData.data.msg);
      } else if (submitData?.msg) {
        alert(submitData.msg);
      }
      resetSubmit();
      closeModal();
    }
  }, [isSuccess, closeModal, submitData, resetSubmit]);

  return (
    <Modal disableClose={true} className="help-modal">
      <Button
        onClick={closeModal}
        className="button-cancel"
        style={{ marginLeft: 10 }}
      >
        &times;
      </Button>
      <img src={MusicHelp} alt="help" className="header-icon" />
      <h1>
        <T>suggest.title</T>
      </h1>
      <p>
        <T>suggest.subtitle</T>
      </p>
      <input
        type="hidden"
        placeholder={i18n.t("suggest.subject")}
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <textarea
        placeholder={i18n.t("suggest.message")}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{ height: 300 }}
      />
      <p className="notifications">
        <T>suggest.notifications</T>
      </p>
      <InputCheckbox
        input={{
          type: "email",
          value: email,
          onChange: (e) => setEmail(e.target.value),
        }}
        checkbox={{
          checked: notifications,
          onChange: (e) => setNotifications(e.target.checked),
        }}
      />
      <Button className="brand" onClick={handleSubmit}>
        {isLoading ? <Loader /> : <T>send</T>}
      </Button>
    </Modal>
  );
}

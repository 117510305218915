import { useEffect, useMemo, useState } from "react";
import useLanguage, { T } from "../../hooks/useLanguage";
import Modal from "../layout/Modal";
import "../../styles/modal-poll.scss";
import Face1 from "../../images/faces/face-line-2.png";
import Face2 from "../../images/faces/face-line-4.png";
import Face3 from "../../images/faces/face-line-6.png";
import Face4 from "../../images/faces/face-line-8.png";
import Face5 from "../../images/faces/face-line-10.png";
import FaceSolid1 from "../../images/faces/face-solid-2.png";
import FaceSolid2 from "../../images/faces/face-solid-4.png";
import FaceSolid3 from "../../images/faces/face-solid-6.png";
import FaceSolid4 from "../../images/faces/face-solid-8.png";
import FaceSolid5 from "../../images/faces/face-solid-10.png";
import Button from "../basic/Button";
import DevPre from "../dev/DevPre";
import { useMutation, useQueryClient } from "react-query";
import { API_PROXY, useUser } from "../../hooks/useApi";
import axios from "axios";
import { useModal } from "../../hooks/useModal";
import usePoll from "../../hooks/usePoll";

export default function PollModal({ userExtra, radioid, nameseo }) {
  const queryClient = useQueryClient();
  const { closeModal } = useModal();
  const { i18n, locale } = useLanguage();
  const [face, setFace] = useState(userExtra?.poll ? userExtra?.poll : null);
  const { closePoll } = usePoll(radioid, nameseo);
  const [comment, setComment] = useState(
    userExtra?.poll_comment ? userExtra?.poll_comment : ""
  );
  const { user } = useUser();

  const faces = useMemo(
    () => ({
      1: {
        image: Face1,
        active: FaceSolid1,
        text: i18n.t("poll.rating_1"),
        locale,
      },
      2: {
        image: Face2,
        active: FaceSolid2,
        text: i18n.t("poll.rating_2"),
        locale,
      },
      3: {
        image: Face3,
        active: FaceSolid3,
        text: i18n.t("poll.rating_3"),
        locale,
      },
      4: {
        image: Face4,
        active: FaceSolid4,
        text: i18n.t("poll.rating_4"),
        locale,
      },
      5: {
        image: Face5,
        active: FaceSolid5,
        text: i18n.t("poll.rating_5"),
        locale,
      },
    }),
    [i18n, locale]
  );

  const pollMutation = useMutation(
    async () => {
      try {
        if (user?.userid === undefined || user?.userid === null) {
          throw new Error("user not logged");
        }
        if (radioid === undefined || radioid === null) {
          throw new Error("Radio not selected");
        }
        const data = await new Promise((onResolve, onReject) =>
          axios
            .get(
              `${API_PROXY}addUserExtra?${new URLSearchParams({
                radioid: radioid,
                token: user?.token,
                extra: JSON.stringify({
                  ...userExtra,
                  poll: face,
                  poll_comment: comment,
                }),
              }).toString()}`
            )
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.invalidateQueries(["extra", radioid, user?.userid]);
        closePoll(true);

        setTimeout(() => {
          closeModal();
        }, 3000);
      },
    }
  );

  const handleCloseModal = () => {
    closePoll(false);
    closeModal();
  };

  return (
    <Modal className={"modal-poll"}>
      <h1>
        <T>poll.title</T>
      </h1>
      <p>
        <T>poll.text</T>
      </p>
      {pollMutation.isSuccess ? (
        <h1>
          <T>poll.thanks</T>
        </h1>
      ) : (
        <>
          <div className="poll-options">
            {Object.keys(faces).map((value) => {
              const _face = faces[value];
              return (
                <button
                  key={value}
                  className={`poll-option ${face === value ? "active" : ""}`}
                  onClick={() => setFace(value)}
                >
                  <img
                    src={face === value ? _face.active : _face.image}
                    alt={_face.text}
                  />
                </button>
              );
            })}
          </div>
          <div className="poll-choose-msg">
            {face ? faces[face].text : i18n.t("poll.choose")}
          </div>
          {face && (
            <label className="poll-comment">
              <p>
                <T>poll.comment</T>
              </p>
              <textarea
                placeholder={i18n.t("poll.comment_placeholder")}
                value={comment}
                onChange={(e) =>
                  setComment(
                    // trim the comment to only 140 characters
                    e.target.value.substring(0, 140)
                  )
                }
                style={{
                  minHeight: 135,
                  borderColor: comment.length >= 140 ? "red" : "",
                }}
              />
            </label>
          )}
          <DevPre>
            {typeof face} {face} {JSON.stringify(userExtra, null, " ")}
          </DevPre>
          <div className="poll-buttons">
            <Button
              className="poll-later bordered brand"
              onClick={handleCloseModal}
            >
              <T>poll.later</T>
            </Button>
            <Button
              className="poll-submit brand"
              disabled={face === null}
              onClick={() => pollMutation.mutate()}
              loading={pollMutation.isLoading}
            >
              <T>poll.send</T>
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

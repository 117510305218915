import { useMemo } from "react";
import VoteStar from "../../../../images/vote-star.png";
const DASHBOARD_SONICBOX_VOTES = 10000;

export default function SongVotes({ votes }) {
  const isBarVote = votes >= DASHBOARD_SONICBOX_VOTES;

  const displayVotes = useMemo(() => {
    if (isBarVote) {
      const barVotes =
        (votes - (votes % DASHBOARD_SONICBOX_VOTES)) / DASHBOARD_SONICBOX_VOTES;
      const clientVotes = votes % DASHBOARD_SONICBOX_VOTES;
      if (clientVotes > 0) {
        return (
          <span>
            {barVotes}
            <sup>{clientVotes}</sup>
          </span>
        );
      }
      return barVotes;
    }
    return votes;
  }, [votes, isBarVote]);

  if (isBarVote) {
    return (
      <span
        className="votes bar-votes"
        style={{ backgroundImage: `url(${VoteStar})` }}
      >
        {displayVotes}
      </span>
    );
  }
  return <span className="votes">{displayVotes}</span>;
}

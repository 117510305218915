import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "react-query";
import axios from "axios";
import { globalHistory, Location, navigate } from "@reach/router";
import { useState } from "react";
import useUpload from "./useUpload";
import { submitRecaptcha } from "../utils/recaptcha";
import { useTerms } from "./useTerms";
import { useCode } from "./useCode";
import usePoll from "./usePoll";

const RadioContext = createContext({
  nameseo: null,
  isTask: false,
  currentRadioid: () => {},
  setCurrentRadioid: () => {},
});
const UserContext = createContext(null);

export const apiQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 30000,
    },
  },
});

const DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const API_PROXY = DEVELOPMENT
  ? "http://localhost:3009/millsonic/proxy/"
  : "https://millsonic.com/api/";
export const API_SONICOLA_URL = DEVELOPMENT
  ? "http://localhost:3009/millsonic/sonicola/"
  : "https://apissl.millsonic.com/sonicola/v1/";
export const API_BACKEND_URL = DEVELOPMENT
  ? "http://localhost:3009/millsonic/backend/"
  : "https://apissl.millsonic.com/backend/v1/";
export const API_DASHBOARD_URL =
  /*DEVELOPMENT
  ? "http://localhost:3009/millsonic/dashboard/"
  :*/ "https://dashboard.millsonic.com/";

function fetchPost(method, dataToSend) {
  // console.log("fetchPost", method, dataToSend);
  return async () => {
    const { data } = await axios.post(API_SONICOLA_URL + method, dataToSend);
    // console.log("fetchPost data", data);
    if (typeof data?.data !== "undefined") {
      return data.data;
    }
    return data;
  };
}

function fetchPostPage(method, initialParams) {
  return async (params) => {
    const sendParams =
      params.pageParam !== undefined ? params.pageParam : initialParams;
    // console.log("fetchPostPage", method, initialParams);
    const { data } = await axios.post(API_SONICOLA_URL + method, sendParams);
    return data;
  };
}

export const useLocals = (lat, lng, search) => {
  /**
   * @type DataToSend
   * @property {number} limit
   * @property {number} offset
   * @property {number} latitude
   * @property {number} longitude
   * @property {string*} search
   */
  const dataToSend = {
    limit: 100,
    offset: 0,
    latitude: -34.90198799981324,
    longitude: -56.13432873602192,
    search: "",
  };
  if (lat !== undefined && lng !== undefined && lat !== null && lng !== null) {
    dataToSend.latitude = lat; //-34.90198799981324
    dataToSend.longitude = lng; //-56.13432873602192,
  }
  if (search !== "" && search !== undefined) {
    dataToSend.search = search;
  }
  const query = useQuery(
    ["locals", lat, lng, search],
    fetchPost(`locals`, dataToSend),
    {
      enabled:
        (search !== "" && search !== undefined) ||
        (lat !== null &&
          lng !== null &&
          lat !== undefined &&
          lng !== undefined),
    }
  );
  return query;
};

export const useRadioidByNameseo = (nameseo) => {
  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      const params = { nameseo: nameseo };
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_BACKEND_URL}getradionameseo`, params)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response.data !== "undefined") {
                    if (typeof response.data.data !== "undefined") {
                      // console.log(
                      //   "getradionameseo response.data.data",
                      //   response.data.data
                      // );
                      onResolve(response.data.data);
                    } else {
                      // console.log(
                      //   "getradionameseo response.data",
                      //   response.data
                      // );
                      onResolve(response.data);
                    }
                  } else {
                    // console.log("getradionameseo response", response);
                    onResolve(response);
                  }
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !!nameseo &&
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 10 * 1000,
    }
  );

  return {
    ...getRadioQuery,
    radioid: getRadioQuery.data?.radioid ? getRadioQuery.data?.radioid : null,
  };
};

export const useLocal = (radioid) => {
  const { currentRadioid, setCurrentRadioid } = useContext(RadioContext);

  useEffect(() => {
    if (radioid !== undefined && currentRadioid !== radioid) {
      setCurrentRadioid(parseInt(radioid));
    }
  }, [radioid, currentRadioid, setCurrentRadioid]);

  const query = useQuery(
    [`singlecola`, parseInt(currentRadioid)],
    async () => {
      try {
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_SONICOLA_URL}singlecola`, {
              radioid: currentRadioid,
            })
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  if (typeof response.data.data !== "undefined") {
                    // console.log(
                    //   "singlecola response.data.data",
                    //   response.data.data
                    // );
                    onResolve(response.data.data);
                  } else {
                    // console.log("singlecola response.data", response.data);
                    onResolve(response.data);
                  }
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        !isNaN(currentRadioid) &&
        currentRadioid !== null &&
        currentRadioid !== undefined,
      refetchInterval: 10 * 1000,
    }
  );

  return {
    ...query,
    radioid: currentRadioid,
    nameseo: query.data?.nameseo ? query.data?.nameseo : null,
  };
};

export const useRadioByNameseo = () => {
  const { nameseo } = useContext(RadioContext);

  // useEffect(() => {
  //   console.log("useTask useEffect change radioid", radioid);
  // }, [radioid]);

  const getRadioQuery = useQuery(
    [`getradionameseo`, nameseo],
    async () => {
      try {
        const params = { nameseo: nameseo };
        const data = await new Promise((onResolve, onReject) =>
          axios
            .post(`${API_BACKEND_URL}getradionameseo`, params)
            .then((response) => {
              if (Object.hasOwnProperty.call(response, "status")) {
                if (response.status === 200) {
                  onResolve(response.data);
                }
              }
              onReject();
            })
            .catch((error) => {
              console.log("Handle error", error);
              onReject(error);
            })
        );
        return data;
      } catch (e) {
        throw e;
      }
    },
    {
      enabled:
        nameseo !== undefined &&
        nameseo !== null &&
        typeof nameseo === "string",
      refetchInterval: 10 * 1000,
    }
  );

  return {
    getRadioQuery: getRadioQuery,
    radioid: getRadioQuery?.data?.radioid,
    nameseo: nameseo,
  };
};

export const useNextSong = (radioid) => {
  const { data: localData } = useLocal(radioid);

  const actualRadioid = useMemo(() => {
    return localData ? localData.radioid : radioid;
  }, [localData, radioid]);

  const query = useQuery(
    [`nextsong`, actualRadioid],
    fetchPost(`nextsong`, {
      // token: token,
      radioid: actualRadioid,
      limit: 100,
      offset: 0,
    }),
    {
      enabled: actualRadioid !== null && typeof actualRadioid !== "undefined",
      refetchInterval: 10000,
    }
  );

  return {
    ...query,
    data: query?.data,
  };
};

export const useUser = () => {
  const {
    token,
    user,
    setUser,
    login,
    logout,
    userInitiated,
    loginRedirect,
    setLoginRedirect,
  } = useContext(UserContext);

  const userQuery = useQuery(
    ["user", token],
    async () => {
      const qF = fetchPost("user", { token: token });
      const data = await qF();
      setUser({ ...data, token: token });
      return data;
    },
    { enabled: token !== null }
  );

  // Define the "login" mutation
  apiQueryClient.setMutationDefaults("login", {
    mutationFn: async (dataToSend) => {
      let url = `login`;
      if (dataToSend?.type === "facebook" || dataToSend?.type === "google") {
        url = `addUser`;
      }
      // console.log("login", url, dataToSend);
      const qF = fetchPost(url, dataToSend);
      return await qF();
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      if (result !== null && result.hasOwnProperty("token")) {
        // Replace optimistic todo in the user list with the result
        apiQueryClient.setQueryData("login", (old) => result);
        login(result.token, result);
      }
      return result;
    },
    onError: (error, variables, context) => {
      // console.log("on login error");
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("login", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const loginMutation = useMutation("login");
  const loginData = loginMutation.data;

  const editMutation = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await axios.post(
        API_SONICOLA_URL + "editUser",
        dataToSend
      );
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on edit success", result);
    },
    onError: (error, variables, context) => {
      console.log("on edit error", error);
    },
    onSettled: (data, error, variables, context) => {
      console.log("on edit settled", data, error);
      apiQueryClient.invalidateQueries("user");
    },
  });

  const updateUser = () => {
    apiQueryClient.invalidateQueries("user");
  };

  const { upload } = useUpload();

  const uploadAvatarMutation = useMutation({
    mutationFn: async (dataToSend) => {
      const { data } = await upload(
        API_SONICOLA_URL + "uploadImage",
        dataToSend
      );
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on upload avatar success", result);
      if (typeof result.status === "string" && result.status === "success") {
        editMutation.mutate({ ...user, avatar: result.url });
      }
    },
    onError: (error, variables, context) => {
      console.log("on upload avatar error", error);
    },
    onSettled: (data, error, variables, context) => {
      console.log("on upload avatar settled", data, error);
    },
  });

  const [notificationid, setNotificationid] = useState(null);

  const verifyEmailMutation = useMutation({
    mutationFn: async (email) => {
      const qF = fetchPost("loginemail", {
        email: email,
      });
      const data = await qF();
      console.log("loginemail data", data);
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on verify success", result, variables, context);
      setNotificationid(result.id);
    },
    onError: (error, variables, context) => {
      console.log("on verify error", error);
    },
    onSettled: (data, error, variables, context) => {
      console.log("on verify settled", data, error);
    },
  });

  const confirmloginMutation = useMutation({
    mutationFn: async (code) => {
      const qF = fetchPost("confirmlogin", {
        notificationid: code,
      });
      const data = await qF();
      console.log("confirmlogin data", data);
      return data;
    },
    onSuccess: (result, variables, context) => {
      console.log("on confirmlogin success", result, variables, context);
      setNotificationid(variables);
    },
  });

  const verifyEmailStatusQuery = useQuery(
    ["verifyEmailStatus", notificationid],
    async () => {
      const qF = fetchPost("checklogin", {
        notificationid: notificationid, // 1249024, //
      });
      const data = await qF();
      console.log("checklogin data", data);
      return data;
    },
    {
      enabled: !!notificationid && confirmloginMutation.isSuccess,
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    if (
      notificationid &&
      verifyEmailStatusQuery.isSuccess &&
      verifyEmailStatusQuery.data?.status === "success"
    ) {
      setNotificationid(null);
      const data = verifyEmailStatusQuery.data.data;
      login(data.token, data);
    }
  }, [
    verifyEmailStatusQuery.data,
    verifyEmailStatusQuery.isSuccess,
    setUser,
    notificationid,
    login,
  ]);

  return {
    mutation: loginMutation,
    userQuery: userQuery,
    login: loginMutation.mutate,
    logout: logout,
    loginData: loginData,
    user: user,
    token: token,
    logged: token !== null,
    initiated: userInitiated,
    updateUser: updateUser,
    loginRedirect: loginRedirect,
    setLoginRedirect: setLoginRedirect,
    editUser: editMutation.mutate,
    editUserMutation: editMutation,
    uploadAvatar: uploadAvatarMutation.mutate,
    uploadAvatarMutation: uploadAvatarMutation,
    verifyEmail: verifyEmailMutation.mutate,
    verifyEmailMutation: verifyEmailMutation,
    notificationid: notificationid,
    verifyEmailStatusQuery: verifyEmailStatusQuery,
    confirmloginMutation,
    setNotificationid,
  };
};

export const useFavorites = () => {
  const [mutatingObserver, setMutatingObserver] = useState(false);
  const { token } = useUser();

  const favoritesQuery = useQuery(
    ["favorites", token],
    fetchPost(
      "favorites",
      {
        token: token,
        action: "list",
      },
      { enabled: token !== null }
    )
  );

  // Define the "addfavorite" mutation
  apiQueryClient.setMutationDefaults("addfavorite", {
    mutationFn: async (dataToSend) => {
      const { data } = await axios.post(`${API_SONICOLA_URL}favorites`, {
        ...dataToSend,
        token: token,
      });
      return data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      updateFavorites();
      setMutatingObserver(true);
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on addfavorite error");
      updateFavorites();
      setMutatingObserver(true);
    },
    retry: 1,
  });

  const mutation = useMutation("addfavorite");

  const updateFavorites = () => {
    apiQueryClient.invalidateQueries("favorites");
  };

  if (!favoritesQuery.isFetching && mutatingObserver) {
    setMutatingObserver(false);
  }

  return {
    ...favoritesQuery,
    mutation: mutation,
    isMutating: mutation.isLoading || mutatingObserver,
    addFavorite: (songid) => {
      mutation.mutateAsync({ action: "add", songid: songid });
    },
    removeFavorite: (songid) => {
      mutation.mutateAsync({ action: "remove", songid: songid });
    },
    updateFavorites: updateFavorites,
  };
};

/**
 * Creates a react-query query for calling "songlist"
 * @param {string} playlistid
 * @param {string} search
 * @param {number} offset
 * @returns {object}
 */
export const useSongs = (playlistid, search, radioid) => {
  const { token } = useUser();

  const SONGS_PER_PAGE = 20;

  const dataToSend = {
    token: token,
    offset: 0,
    limit: SONGS_PER_PAGE,
    radioid: radioid,
  };

  if (search !== "") {
    dataToSend.filter = search;
  } else if (playlistid !== "") {
    dataToSend.playlistid = playlistid;
  }

  const songsQuery = useInfiniteQuery(
    ["songlist", token, dataToSend.radioid, playlistid, search],
    fetchPostPage("songlist", dataToSend, { enabled: token !== null }),
    {
      getNextPageParam: (lastPage, pages) => {
        // console.log("getNextPageParam", lastPage, pages);
        const pageParams = {
          token: token,
          offset: pages.length * SONGS_PER_PAGE,
          limit: SONGS_PER_PAGE,
          radioid: dataToSend.radioid,
        };
        if (search !== "") {
          pageParams.filter = search;
        }
        if (playlistid !== "") {
          pageParams.playlistid = playlistid;
        }
        return pageParams;
      },
      enabled:
        (typeof dataToSend.radioid === "string" ||
          typeof dataToSend.radioid === "number") &&
        (playlistid !== "" || search !== ""),
      keepPreviousData: true,
      staleTime: 1000,
      getPreviousPageParam: (firstPage, pages) => {
        if (pages.length === 0) return undefined;
        return (pages.length - 1) * SONGS_PER_PAGE;
      },
    }
  );

  console.log("songsQuery", songsQuery?.data);

  const songs = songsQuery.data?.pages
    ?.map((page) => page.data)
    .flat()
    .filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.songid === value.songid)
    );

  return {
    songs: songs,
    total: songsQuery?.data?.pages[0].total,
    loadMore: songsQuery.fetchNextPage,
    ...songsQuery,
  };
};

export const useSingleSong = (radioid, songname, songid) => {
  const { user } = useUser();
  const { token } = user;

  const parts =
    typeof songname === "string" && songname !== ""
      ? songname.split(" - ")
      : [songname];

  let filter = songname;

  if (parts.length > 0) {
    parts.shift();
    filter = parts.join(" ");
  }
  filter = filter.slice(0, 8);

  const dataToSend = {
    token,
    limit: 1000,
    offset: 0,
    radioid,
    filter,
  };

  const songQuery = useQuery(
    ["singlesong", radioid, songname, songid],
    fetchPost("songlist", dataToSend)
  );

  return {
    ...songQuery,
    song: songQuery.data?.find((song) => song.songid === songid),
  };
};

export const usePlaylist = (
  radioid,
  playlistid,
  random = true,
  offset = 0,
  limit = 30
) => {
  const { user } = useUser();
  const { token } = user;
  const playlistQuery = useQuery(
    ["playlist", playlistid, radioid, offset, limit],
    fetchPost("songlist", {
      token,
      limit,
      offset,
      radioid,
      playlistid,
    })
  );

  const songs = useMemo(() => {
    const data = playlistQuery?.data?.data
      ? playlistQuery?.data?.data
      : playlistQuery?.data
      ? playlistQuery?.data
      : [];
    // console.log("playlistQuery data", playlistQuery?.data);
    // console.log("songs data", data);
    if (random) {
      return data.sort(() => Math.random() - 0.5);
    }
    return data;
  }, [playlistQuery.data, random]);

  return { ...playlistQuery, songs };
};

export const useVote = (radioid, nameseo) => {
  const { user } = useUser();
  const { token } = user;
  const { burnPriceCode } = useCode();
  const { sendVote, sendRequest } = useInform(nameseo);
  const { registerInteraction } = usePoll(radioid, nameseo);

  const priceMutation = useMutation(
    ({ songid }) =>
      axios.get(
        `${API_DASHBOARD_URL}price/getPrice?token=${token}&songid=${songid}&radioid=${radioid}`
      ),
    {
      onSuccess: (data) => {
        console.log("priceMutation data", data);
        burnPriceCode(radioid);
      },
      onError: (error) => {
        console.log("priceMutation error", error);
      },
    }
  );

  // Define the "vote" mutation
  apiQueryClient.setMutationDefaults("vote", {
    retries: 1,
    mutationFn: async (params) => {
      if (params.scid !== undefined) {
        const dataToSend = {
          token: token,
          votes: params.votes,
          scid: params.scid,
          radioid: radioid,
        };
        const response = await axios.post(
          `${API_SONICOLA_URL}votesong`,
          dataToSend
        );
        console.log(
          "votesong response.data",
          typeof response.data,
          response?.data,
          response?.data?.status
        );
        if (typeof response.data === "object") {
          if (response?.data?.status === "success") {
            const infoParams = {
              songid: params.songid,
              songname: params.songname,
              artist: params.artist,
              radioid: radioid,
              nameseo: nameseo,
              votes: params.votes,
            };
            sendVote(infoParams);
          }
          return response.data;
        } else {
          return null;
        }
      } else {
        const dataToSend = {
          token: token,
          radioid: radioid,
          votes: params.votes,
          songid: params.songid,
        };
        const response = await axios.post(
          `${API_SONICOLA_URL}requestsong`,
          dataToSend
        );
        console.log(
          "requestsong response.data",
          typeof response.data,
          response.data,
          response?.data?.status
        );
        if (typeof response.data !== "undefined") {
          if (response?.data?.status === "success") {
            const infoParams = {
              songid: params.songid,
              songname: params.songname,
              artist: params.artist,
              radioid: radioid,
              nameseo: nameseo,
              votes: params.votes,
            };
            sendRequest(infoParams);
          }
          return response.data;
        } else {
          console.log("requestsong response", response);
          return response;
        }
      }
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("vote", (old) => result);
      apiQueryClient.invalidateQueries(["singlecola"]);
      apiQueryClient.invalidateQueries(["nextsong"]);
      apiQueryClient.invalidateQueries(["user"]);
      registerInteraction();
      return result;
    },
    onError: (error, variables, context) => {
      console.log("on vote error", error, error.response, variables, context);
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("vote", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("vote");

  const vote = (params) => {
    mutation.mutateAsync(params);
  };

  const voteData = mutation.data;

  return {
    mutation: mutation,
    vote: vote,
    voteData: voteData,
    priceMutation: priceMutation,
  };
};

export const useCards = () => {
  const { user } = useUser();
  const { token } = user;

  const cardsQuery = useQuery(
    ["cards", token],
    fetchPost("cards", { token: token }),
    { enabled: token !== null }
  );

  const updateCards = () => {
    apiQueryClient.invalidateQueries("cards");
  };

  // Define the "addCard" mutation
  apiQueryClient.setMutationDefaults("addCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        name: params.name,
        card_num: params.card_num,
        exp_month: params.exp_month,
        exp_year: params.exp_year,
        stripeToken: params.stripeToken,
        cvc: params.cvc,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}addCard`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      console.log("on addCard success", result);
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("addCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on addCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("addCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const addCardMutation = useMutation("addCard");

  const addCard = async (params, callback = () => {}) => {
    console.log("useApi addCard ", params);
    try {
      const codeResponse = await addCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const addCardData = addCardMutation.data;

  // Define the "deleteCard" mutation
  apiQueryClient.setMutationDefaults("deleteCard", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ucid: params.ucid,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}deletecard`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("deleteCard", (old) => result);
      apiQueryClient.invalidateQueries("cards");
      return result;
    },
    onError: (error, variables, context) => {
      console.log(
        "on deleteCard error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("deleteCard", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const deleteCardMutation = useMutation("deleteCard");

  const deleteCard = async (params, callback = () => {}) => {
    try {
      const codeResponse = await deleteCardMutation.mutateAsync(params);
      if (codeResponse !== undefined) {
        callback();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const deleteCardData = deleteCardMutation.data;

  return {
    cardsQuery: cardsQuery,
    cards: cardsQuery.data?.cards,
    isLoading: cardsQuery.isLoading,
    isError: cardsQuery.isError,
    updateCards: updateCards,
    error: cardsQuery.error,
    addCardMutation: addCardMutation,
    addCard: addCard,
    addCardData: addCardData,
    deleteCardMutation: deleteCardMutation,
    deleteCard: deleteCard,
    deleteCardData: deleteCardData,
  };
};

export const ApiProvider = ({ children }) => {
  const [currentRadioid, setCurrentRadioid] = useState(null);
  const [user, setUser] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const [token, setToken] = useState(null);
  const isBrowser = useCallback(() => typeof window !== "undefined", []);

  const [loginRedirect, setLoginRedirect] = useState("/sonicboxes");

  useEffect(() => {
    const savedLogin = window.localStorage.getItem("redirectLogin");
    if (typeof savedLogin === "string" && savedLogin !== "") {
      setLoginRedirect(savedLogin);
    }
  }, []);

  const saveUser = useCallback((user) => {
    window.localStorage.setItem("sonicboxUser", JSON.stringify(user));
  }, []);

  const getUser = useCallback(() => {
    if (isBrowser() && window.localStorage.getItem("sonicboxUser")) {
      const storedUser = JSON.parse(
        window.localStorage.getItem("sonicboxUser")
      );
      // console.log("storedUser", storedUser)
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        return storedUser;
      }
    }
    return { token: null, name: "" };
  }, [isBrowser]);

  useEffect(() => {
    if (user === null) {
      const storedUser = getUser();
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        setUser(storedUser);
        setToken(storedUser.token);
      }
    } else {
      saveUser(user);
    }
    setInitiated(true);
  }, [user, getUser, saveUser]);

  const login = useCallback((newToken, newUser) => {
    setToken(newToken);
    setUser(newUser);
    setTimeout(() => {
      const redirectLogin = window.localStorage.getItem("redirectLogin");
      if (typeof redirectLogin === "string" && redirectLogin !== "") {
        window.localStorage.removeItem("redirectLogin");
        navigate(redirectLogin);
      } else {
        navigate("/sonicboxes");
      }
    }, 500);
  }, []);

  const [ticker, setTicker] = useState(null);
  useEffect(() => {
    if (ticker === null) {
      setInterval(() => {
        setTicker(new Date().getTime());
      }, 5000);
    }
  }, [ticker]);

  useEffect(() => {
    if (ticker !== null && user === null) {
      const storedUser = getUser();
      if (storedUser.hasOwnProperty("token") && storedUser.token !== null) {
        login(storedUser.token, storedUser);
      }
    }
  }, [user, getUser, ticker, login]);

  const logout = useCallback(
    (callback) => {
      apiQueryClient.removeQueries(["user", token]);
      console.log("logout");
      console.log(window.FB);
      if (window?.FB) {
        // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
        window.FB.api("/me/permissions", "delete", null, (...args) => {
          console.log("FB delete permissions callback", args);
          window.FB.logout();
        });
      }
      setUser({ token: null, name: "" });
      saveUser("");
      setToken(null);
      setCurrentRadioid(null);
      navigate("/");
      if (typeof callback === "function") callback();
    },
    [token, setUser, setToken, saveUser]
  );

  useEffect(() => {
    globalHistory.listen(({ pathname }) => {
      console.log("pathname", pathname);
    });
  }, []);

  return (
    <Location>
      {({ location }) => {
        const matchedRadio = location.pathname.match(/\/sonicbox\/(.+)/);
        const radioNameseo =
          matchedRadio !== null ? parseInt(matchedRadio[1]) : null;
        const matchedTaskNameseo = location.pathname.match(/\/task\/(.+)/);
        const taskNameseo =
          matchedTaskNameseo !== null ? matchedTaskNameseo[1] : null;
        const isTask = taskNameseo !== null;

        return (
          <RadioContext.Provider
            value={{
              nameseo: isTask ? taskNameseo : radioNameseo,
              isTask: isTask,
              currentRadioid: currentRadioid,
              setCurrentRadioid: setCurrentRadioid,
            }}
          >
            <UserContext.Provider
              value={{
                user: user,
                setUser: setUser,
                token: token,
                userInitiated: initiated,
                setToken: setToken,
                login: login,
                logout: logout,
                loginRedirect: loginRedirect,
                setLoginRedirect: setLoginRedirect,
              }}
            >
              <QueryClientProvider client={apiQueryClient}>
                {children}
              </QueryClientProvider>
            </UserContext.Provider>
          </RadioContext.Provider>
        );
      }}
    </Location>
  );
};

export const getNameseoByRadioid = async (radioid) => {
  const response = await axios.post(`${API_SONICOLA_URL}singlecola`, {
    radioid,
  });
  console.log("response", response);
  if (response?.data?.data?.data?.nameseo) {
    return response.data.data.data.nameseo;
  }
  if (response?.data?.data?.nameseo) {
    return response.data.data.nameseo;
  }
  if (response?.data?.nameseo) {
    return response.data.nameseo;
  }
  return null;
};

export const useContact = () => {
  const mutation = useMutation({
    mutationFn: (data) => {
      return submitRecaptcha(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY).then(
        (recaptchaToken) => {
          if (!recaptchaToken) {
            throw new Error("Recaptcha error");
          }
          console.log("recaptchaToken", recaptchaToken);
          return axios.post("https://millsonicbox.com/contact.php", {
            ...data,
            recaptcha: recaptchaToken,
          });
        }
      );
    },
    onSuccess: (data) => {
      console.log("data", data);
    },
  });
  return mutation;
};

const BCC_EVENT_NAME_SCAN = ["scan_pdv", "scan_creditos"];
const BCC_EVENT_NAME_VOTE = [
  "voto_cancion",
  "voto_artista",
  "voto_credits",
  "voto_pdv",
];
const BCC_EVENT_NAME_REQUEST = [
  "elegir_cancion",
  "elegir_artista",
  "elegir_creditos",
  "elegir_pdv",
];

const InformContext = createContext({
  nameseo: null,
  setNameseo: () => {},
  sendVote: ({ nameseo, radioid, votes, songname, artist }) => {},
  sendRequest: ({ nameseo, radioid, votes, songname, artist }) => {},
  sendScan: (credits, radioid, nameseo) => {},
});

export const InformProvider = ({ children }) => {
  const [nameseo, setNameseo] = useState(null);
  const { token, user } = useUser();
  const { userExtra } = useTerms(nameseo);
  const pendingEvents = useRef([]);

  const send = useCallback(
    async (data, index = -1) => {
      console.log("send inform", nameseo, data, index, userExtra);
      if (token && userExtra?.cedula && nameseo) {
        if (userExtra?.nacimiento && userExtra?.nacimiento !== "") {
          const nacimiento = userExtra.nacimiento.split("-");
          data.yearofbirth = nacimiento[0];
          data.monthofbirth = nacimiento[1];
          data.dayofbirth = nacimiento[2];
        }
        data.firstname = userExtra?.nombre ? userExtra.nombre : "";
        data.lastname = userExtra?.apellido ? userExtra.apellido : "";
        data.email = user?.email ? user.email : "";
        data.cpf = userExtra?.cedula ? userExtra.cedula : "";
        data.tcpp = !!userExtra?.checkTerms && !!userExtra?.checkPrivacy;
        data.optin_email =
          !!userExtra?.promoEmails && !!userExtra?.checkMarketing;
        data.optin_text = !!userExtra?.promoSMS && !!userExtra?.checkMarketing;
        data.optin_phone =
          !!userExtra?.promoNoti && !!userExtra?.checkMarketing;
        data.optin_extrahours =
          !!userExtra?.promoHorario && !!userExtra?.checkMarketing;
        data.optin_call =
          !!userExtra?.promoCalls && !!userExtra?.checkMarketing;
        data.optin_mkt_activation = !!userExtra?.checkMarketing;

        if (index > -1) {
          pendingEvents.current.splice(index, 1);
        }
        return axios.post("https://millsonicbox.com/inform.php", data);
      } else {
        console.warn(
          "do not send until user data is complete",
          userExtra,
          data
        );
        if (index === -1) {
          pendingEvents.current.push(data);
        }
      }
    },
    [token, userExtra, nameseo]
  );

  const processPendingEvents = useCallback(() => {
    console.log(
      "processPendingEvents",
      userExtra,
      token,
      send,
      pendingEvents.current
    );
    for (let i = pendingEvents.current.length - 1; i >= 0; i--) {
      const data = pendingEvents.current[i];
      send(data, i);
    }
  }, [userExtra, token, send]);

  useEffect(processPendingEvents, [
    processPendingEvents,
    userExtra,
    token,
    send,
  ]);

  const sendRequest = async ({ nameseo, radioid, votes, songname, artist }) => {
    const data = {
      event_names: BCC_EVENT_NAME_REQUEST,
      event_values: [songname, artist, votes, nameseo],
      radioid,
      radioname: nameseo,
    };
    return send(data);
  };

  const sendVote = async ({ nameseo, radioid, votes, songname, artist }) => {
    const data = {
      event_names: BCC_EVENT_NAME_VOTE,
      event_values: [songname, artist, votes, nameseo],
      radioid,
      radioname: nameseo,
    };
    return send(data);
  };

  const sendScan = async (credits, radioid, nameseo) => {
    const data = {
      event_names: BCC_EVENT_NAME_SCAN,
      event_values: [nameseo, credits],
      radioid,
      radioname: nameseo,
    };
    return send(data);
  };

  return (
    <InformContext.Provider
      value={{
        nameseo,
        setNameseo,
        sendVote,
        sendRequest,
        sendScan,
        processPendingEvents,
      }}
    >
      {children}
    </InformContext.Provider>
  );
};

export const useInform = (nameseo) => {
  const { setNameseo, ...values } = useContext(InformContext);

  useEffect(() => {
    if (nameseo) {
      setNameseo(nameseo);
    }
  }, [nameseo, setNameseo]);

  return values;
};

import React, { useEffect } from "react";
import { navigate, useLocation } from "@reach/router";
import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "./styles/sonicboxes.scss";
import Locales from "./components/Locales";
import { T } from "../../../hooks/useLanguage";
import SearchLocales from "./components/SearchLocales";
import { useRef } from "react";
import interpolate from "../../../utils/interpolate";
import StickyHeader from "../../layout/StickyHeader";
import useTheme from "../../../hooks/useTheme";

export default function Sonicboxes() {
  const { setTheme } = useTheme();
  const location = useLocation();
  const logoRef = useRef(null);

  const isSearch = location.search.indexOf("?s") === 0;

  useEffect(() => {
    if (location.pathname.indexOf("/sonicboxes") === 0) {
      setTheme("default");
    }
  }, [setTheme, location.pathname]);

  return (
    <div className="container sonicboxes">
      {isSearch ? (
        <SearchLocales />
      ) : (
        <div>
          <StickyHeader
            startHeight={8}
            endHeight={8}
            style={{
              justifyContent: "center",
              alginItems: "center",
              padding: "2em 2em 4em",
            }}
            className="sonicboxes-header"
            callback={(scrollY, containerRef) => {
              if (containerRef.current) {
                const paddingTop = interpolate(scrollY, [0, 200], [6, 2]);
                containerRef.current.style.paddingTop = `${paddingTop}em`;
              }
            }}
            animate={false}
          >
            <img
              ref={logoRef}
              src={SonicboxLogoPNG}
              alt="Sonicbox"
              style={{
                width: "auto",
                height: 80,
                // background: "#0f03",
              }}
            />
          </StickyHeader>
          <Locales
            onEnter={(nameseo) => navigate(`/sonicbox/${nameseo}`)}
            buttonLabel={<T>explorer.getin</T>}
          />
        </div>
      )}
    </div>
  );
}

import { useEffect, useMemo, useRef, useState } from "react";
import { useTerms } from "../../hooks/useTerms";
import DevPre from "../dev/DevPre";
import { useUser } from "../../hooks/useApi";
import ParqueAraucoTermsText from "./ParqueAraucoTermsText";

const inputs = {
  nombre: { defaultValue: "", required: true, type: "text" },
  apellido: { defaultValue: "", required: true, type: "text" },
  correo: { defaultValue: "", required: true, type: "text" },
  cedula: { defaultValue: "", required: true, type: "text" },
  nacimiento: { defaultValue: "", required: true, type: "datePlus18" },
  celular: { defaultValue: "", required: true, type: "text" },
  declare: { defaultValue: false, required: true, type: "check" },
  authorize: { defaultValue: false, required: true, type: "check" },
};

export default function ParqueAraucoTerms({
  setChecked,
  onAccept,
  isPeeking,
  nameseo,
  pendingTerms,
}) {
  const [isReady, setIsReady] = useState(false);
  const [state, setState] = useState(
    Object.keys(inputs).reduce((acc, key) => {
      acc[key] = inputs[key].defaultValue;
      return acc;
    }, {})
  );

  const lastState = useRef("");

  const { userExtra, ready: termsReady, termsNameseo } = useTerms();
  const { user } = useUser();

  const [openPreferences, setOpenPreferences] = useState(false);

  useEffect(() => {
    console.log("initial state", nameseo, termsNameseo, userExtra);
    if (termsReady && !isReady && nameseo === termsNameseo) {
      let initialState = Object.keys(inputs).reduce((acc, key) => {
        acc[key] = userExtra?.[key] || inputs[key].defaultValue;
        return acc;
      }, {});

      if (pendingTerms) {
        const pendingTermsString = JSON.stringify(pendingTerms);
        if (pendingTermsString !== lastState.current) {
          console.log("pendingTerms", pendingTerms);
          initialState = { ...initialState, ...pendingTerms };
          const storedPendingTerms =
            window.localStorage.getItem(`pendingterms`);
          if (storedPendingTerms) {
            const storedPendingTermsParsed = JSON.parse(storedPendingTerms);
            if (storedPendingTermsParsed.hasOwnProperty(nameseo)) {
              delete storedPendingTermsParsed[nameseo];
            }
            window.localStorage.setItem(
              `pendingterms`,
              JSON.stringify(storedPendingTermsParsed)
            );
          }
        }
      }
      console.log("setState initial state", initialState);
      if (initialState?.nacimiento) {
        const split = initialState.nacimiento.split("-");
        if (split.length === 3) {
          const date = new Date();
          date.setFullYear(split[0]);
          date.setMonth(split[1]);
          date.setDate(split[2]);
          initialState.nacimiento = date;
        } else {
          delete initialState.nacimiento;
        }
      }
      setState(initialState);
      setIsReady(true);
    }
  }, [termsReady, isReady, userExtra, nameseo, termsNameseo, pendingTerms]);

  const handleUpdateState = (key, value) => {
    console.log("setState handleUpdateState", key, value);
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  useEffect(() => {
    setChecked({
      ...state,
      nacimiento:
        state.nacimiento instanceof Date
          ? state.nacimiento.toISOString().split("T")[0]
          : "",
      promoSMS: state.promoSMS && state.checkMarketing,
      promoEmails: state.promoEmails && state.checkMarketing,
      promoCalls: state.promoCalls && state.checkMarketing,
      promoNoti: state.promoNoti && state.checkMarketing,
      promoHorario: state.promoHorario && state.checkMarketing,
    });
  }, [state, setChecked]);

  useEffect(() => {
    const stateString = JSON.stringify(state);
    if (stateString !== lastState.current) {
      lastState.current = stateString;
      const acceptAll = Object.keys(inputs).every((key) => {
        const value = state[key];
        const input = inputs[key];
        if (!input.required) return true;
        if (key === "correo" && user?.email) return true;
        switch (input.type) {
          case "datePlus18":
            const date = new Date(value);
            const _18YearsAgo = new Date();
            _18YearsAgo.setFullYear(_18YearsAgo.getFullYear() - 18);
            console.log("datePlus18", date, _18YearsAgo, date <= _18YearsAgo);
            return input.type === "datePlus18" && date <= _18YearsAgo;
          case "text":
            console.log("text", input, value);
            return value !== "";
          case "check":
          default:
            console.log("check", input, value);
            return value === true;
        }
      });
      console.log("acceptAll", acceptAll, state);
      onAccept(acceptAll);
    }
  }, [state, onAccept, user?.email]);

  return (
    <div style={{ position: "relative" }}>
      <DevPre>{JSON.stringify(pendingTerms, null, " ")}</DevPre>
      <ParqueAraucoTermsText />

      <FormCheckbox
        label={
          <>
            Para efectos de esta autorización declaro que los datos personales
            que entregaré para ser sometidos a tratamiento son verídicos y que
            los he suministrado de forma voluntaria. Así mismo, declaro que
            estoy autorizado para entregar los datos personales de los terceros
            que suministre a Parque Arauco.
          </>
        }
        value={state.declare}
        onChange={(value) => handleUpdateState("declare", value)}
        readOnly={isPeeking}
      />

      <FormCheckbox
        label={
          <>
            Autorizo, en calidad de representante legal a PACSAS para realizar
            el tratamiento de los Datos Personales de los menores a los que
            represento de conformidad con las finalidades arriba indicadas. Así
            mismo, declaro que estoy autorizado para entregar los datos
            personales de los menores de edad que suministre a Parque Arauco.
          </>
        }
        value={state.authorize}
        onChange={(value) => handleUpdateState("authorize", value)}
        readOnly={isPeeking}
      />
      <FormInput
        label="Nombre"
        placeholder="Ingresa tu nombre"
        value={state.nombre}
        onChange={(value) => handleUpdateState("nombre", value)}
      />
      <FormInput
        label="Apellido"
        placeholder="Ingresa tu apellido"
        value={state.apellido}
        onChange={(value) => handleUpdateState("apellido", value)}
      />
      <FormInput
        label="Correo electrónico"
        placeholder="email@email.com"
        value={user?.email ? user?.email : state.correo}
        onChange={(value) => handleUpdateState("correo", value)}
        readOnly={!!user?.email}
      />
      <FormInput
        label="Cédula"
        placeholder="Ingresa tu cédula"
        value={state.cedula}
        onChange={(value) => handleUpdateState("cedula", value)}
      />
      <DevPre>
        {JSON.stringify(state.nacimiento)} {typeof state.nacimiento}
      </DevPre>
      <DateInput
        label="Fecha de nacimiento"
        value={isReady ? state.nacimiento : null}
        onChange={(value) => handleUpdateState("nacimiento", value)}
      />
      <FormInput
        label="Teléfono celular"
        placeholder="Ingresa tu número de celular"
        value={state.celular}
        onChange={(value) => handleUpdateState("celular", value)}
      />
    </div>
  );
}

function FormInput({ label, placeholder, value, onChange, readOnly = false }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 6,
        marginBottom: 14,
        textAlign: "left",
        width: "100%",
      }}
    >
      <label
        style={{
          fontSize: 14,
          fontWeight: "500",
          textAlign: "justify",
          flex: 1,
        }}
      >
        {label}
      </label>
      <input
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
      />
    </div>
  );
}

function FormCheckbox({ label, value, onChange, readOnly = false }) {
  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        marginBottom: 26,
        textAlign: "left",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
        style={{ width: 20, height: 20, border: "none", borderRadius: 4 }}
        disabled={readOnly}
      />
      <label
        style={{
          textAlign: "left",
          flex: 1,
        }}
      >
        {label}
      </label>
    </div>
  );
}

function DateInput({ label, value, onChange }) {
  const [ready, setReady] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const lastDate = useRef("");

  useEffect(() => {
    if (!ready && value !== null) {
      if (value instanceof Date) {
        setDay(value.getDate());
        setMonth(value.getMonth());
        setYear(value.getFullYear());
      } else if (typeof value === "string") {
        const split = value.split("-");
        console.log("date value split", value, split);
        if (split.length === 3) {
          setYear(split[0]);
          setMonth(split[1]);
          setDay(split[2]);
        }
      } else {
        console.log("date value ready", value, typeof value);
      }
      setReady(true);
    }
  }, [value, ready]);

  const dayOptions = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    const months31 = [0, 2, 4, 6, 7, 9, 11];
    const months30 = [3, 5, 8, 10];
    const month29 =
      year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? [1] : [];
    const month28 = month29.length === 0 ? [1] : [];

    let monthDays = months31.includes(month)
      ? 31
      : months30.includes(month)
      ? 30
      : month29.length === 1
      ? 29
      : 28;

    if (year === currentYear && month === currentMonth) {
      monthDays = currentDay;
    }

    const options = new Array(monthDays).fill(0).map((_, index) => index + 1);
    return options;
  }, [month, year]);

  const monthOptions = useMemo(() => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ].map((month, index) => ({ key: index, value: month }));
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    return months.filter(
      (month) => year < currentYear || month.key <= currentMonth
    );
  }, [year]);

  const yearOptions = useMemo(() => {
    const currentYear = new Date().getFullYear() - 18;
    const years = new Array(100).fill(0).map((_, index) => currentYear - index);
    return years;
  }, []);

  useEffect(() => {
    if (ready) {
      const date = new Date();
      date.setHours(12, 0, 0, 0);
      const dateString = `${year}-${month + 1}-${day}`;
      if (dateString !== lastDate.current) {
        lastDate.current = dateString;
        if (day === "" || month === "" || year === "") {
          if (value !== "") {
            onChange("");
          }
        } else {
          date.setFullYear(year);
          date.setMonth(month);
          date.setDate(day);
          if (
            !(value instanceof Date) ||
            date.toDateString() !== value.toDateString()
          ) {
            console.log(
              "handleOnChange",
              typeof value,
              value,
              " : ",
              day,
              month,
              year
            );
            onChange(date);
          }
        }
      }
    }
  }, [day, month, year, value, onChange, ready]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 6,
        marginBottom: 14,
        textAlign: "left",
        width: "100%",
      }}
    >
      <label
        style={{
          fontSize: 14,
          fontWeight: "500",
          textAlign: "justify",
          flex: 1,
        }}
      >
        {label}
      </label>
      <div style={{ display: "flex", gap: 10 }}>
        <select
          className="form-control"
          value={day}
          onChange={(e) => setDay(e.target.value)}
          style={{
            fontSize: 16,
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "18%",
            width: "auto",
          }}
        >
          <option value="">Día</option>
          {dayOptions.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
        <select
          className="form-control"
          value={month}
          onChange={(e) => setMonth(parseInt(e.target.value))}
          style={{
            fontSize: 16,
            flexGrow: 3,
            flexShrink: 3,
            flexBasis: "calc(52% - 10px)",
            width: "auto",
          }}
        >
          <option value="">Mes</option>
          {monthOptions.map((month) => (
            <option key={month.key} value={month.key}>
              {month.value}
            </option>
          ))}
        </select>
        <select
          className="form-control"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          style={{
            fontSize: 16,
            flexGrow: 2,
            flexShrink: 2,
            flexBasis: "calc(30% - 10px)",
            width: "auto",
          }}
        >
          <option value="">Año</option>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import DevPre from "../../dev/DevPre";
import "./styles/style.scss";
import { useUser } from "../../../hooks/useApi";
import Button from "../../basic/Button";
import { useNavigate } from "@reach/router";

import useLanguage, { LangSwitcher, T } from "../../../hooks/useLanguage";
import LanguageIcon from "../../../images/icon-language.png";

export default function EmailLogin({ code }) {
  const navigate = useNavigate();
  const { i18n } = useLanguage();

  const { mutation: loginMutation, confirmloginMutation } = useUser();

  useEffect(() => {
    if (confirmloginMutation?.status === "idle") {
      confirmloginMutation.mutate(code);
    }
    // return () => confirmloginMutation.reset();
  }, [confirmloginMutation, code]);

  useEffect(() => {
    return () => confirmloginMutation.reset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="email-login">
      {confirmloginMutation.isSuccess && (
        <>
          <h1>
            <T>emaillogin.title</T>
          </h1>
          <p>
            <T>emaillogin.subtitle</T>
          </p>
          <br />
          <Button onClick={() => navigate("/")} style={{ textAlign: "center" }}>
            <T>emaillogin.continue</T>
          </Button>
        </>
      )}
      {confirmloginMutation.isError && (
        <>
          <h1>
            <T>emaillogin.errorTitle</T>
          </h1>
          <p>
            <T>emaillogin.errorSubtitle</T>
          </p>
          <p>
            <small>{confirmloginMutation?.error?.message}</small>
          </p>
          <br />
          <Button onClick={() => navigate("/")} className="success">
            <T>emaillogin.loginagain</T>
          </Button>
        </>
      )}
      <div className="lang-switcher-container">
        <img
          src={LanguageIcon}
          alt={i18n.t("profile.Language")}
          className="icon"
        />
        <T>home.language</T>
        <LangSwitcher />
      </div>
      <DevPre>{loginMutation.status}</DevPre>
    </div>
  );
}

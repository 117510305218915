export default function TermsFormGroup({
  label,
  input,
  small,
  link,
  onChange,
  isPeeking,
}) {
  let labelText = label?.textContent;
  const inputName = input?.name;
  const smallText = small?.textContent;
  const linkHref = link?.href;
  const linkText = link?.textContent;
  const required = !!input?.required;

  if (link && linkHref && linkText) {
    labelText = labelText.replace(linkText, "");
  }

  if (isPeeking) {
    return (
      link && (
        <div
          className="form-group"
          style={{ display: "flex", marginBottom: 10 }}
        >
          <a
            href={linkHref}
            target="_blank"
            rel="noreferrer"
            style={{ whiteSpace: "pre" }}
          >
            {linkText}
          </a>
        </div>
      )
    );
  }

  const formGroupStyle =
    input.type === "checkbox"
      ? { display: "flex", marginBottom: 10 }
      : {
          display: "flex",
          flexDirection: "row",
          marginBottom: 10,
          alignItems: "center",
          flexWrap: "wrap",
        };

  const inputStyle =
    input.type === "checkbox"
      ? {
          width: 20,
          height: 20,
          flexGrow: 0,
          flexShrink: 0,
          marginRight: 20,
        }
      : {
          flexGrow: 1,
          flexShrink: 1,
          marginRight: 20,
          color: "black",
          backgroundColor: "white",
          width: "100%",
          maxWidth: 320,
        };

  const labelContainerStyle =
    input.type === "checkbox"
      ? {}
      : {
          flexGrow: 1,
          alignItems: "center",
        };

  const labelStyle =
    input.type === "checkbox"
      ? {}
      : {
          whiteSpace: "nowrap",
        };

  return (
    <div className="form-group" style={formGroupStyle}>
      <input
        type={input.type}
        onChange={onChange}
        name={inputName}
        required={required}
        style={inputStyle}
      />
      <div style={labelContainerStyle}>
        <label style={labelStyle}>
          {labelText}
          {link && (
            <a
              href={linkHref}
              target="_blank"
              rel="noreferrer"
              style={{ whiteSpace: "pre" }}
            >
              {linkText}
            </a>
          )}
        </label>{" "}
        <small style={{ opacity: 0.5 }}>{smallText}</small>
      </div>
    </div>
  );
}

import { useMemo } from "react";
import { useUser } from "../../hooks/useApi";
import "../../styles/points-badge.scss";

export default function PointsBadge({ size = "2.75em" }) {
  const { user } = useUser();
  const points = user?.points || 0;

  const [scale] = useMemo(() => {
    if (points < 10) return [1];
    if (points < 100) return [0.75];
    if (points < 1000) return [0.5];
    if (points < 10000) return [0.33];
    return [0.25];
  }, [points]);

  const fontSize = useMemo(() => {
    const floatEm = parseFloat(size.replace("em", "")) * 0.75;
    return `${floatEm * scale}em`;
  }, [size, scale]);

  return (
    <div className="points-badge" style={{ width: size, height: size }}>
      <span
        style={{
          fontSize,
        }}
      >
        {points}
      </span>
    </div>
  );
}

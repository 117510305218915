import React from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-success.scss";
import Loader from "../basic/Loader";
import useLanguage from "../../hooks/useLanguage";

const AddCreditSuccessModal = ({ credits }) => {
  const { i18n } = useLanguage();

  return (
    <Modal className="modal-success add-credit-success">
      {credits ? (
        <>
          <h2 style={{ marginBottom: "auto" }}>
            <span>{i18n.t("addcreditssuccess.line1")}</span>
            <small>{i18n.t("addcreditssuccess.line2")}</small>
            <strong>
              {i18n.t("addcreditssuccess.line3", { count: credits })}
            </strong>
            <small>
              {i18n.t("addcreditssuccess.line4", { count: credits })}
            </small>
          </h2>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default AddCreditSuccessModal;

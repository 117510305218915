import { Link } from "@reach/router";
import {
  PROFILE_SECTION_PROFILE,
  PROFILE_SECTION_CREDITS,
  PROFILE_SECTION_FAVORITES,
  HELP_MODAL,
  POLL_MODAL,
  SUGGEST_MODAL,
} from "../../../constants";
import { useLocal, useUser } from "../../../hooks/useApi";
import useLanguage, { LangSwitcher, T } from "../../../hooks/useLanguage";
import PointsBadge from "../../basic/PointsBadge";
import ProfileButton from "./components/ProfileButton";
import "./styles/menu.scss";
import FavoritesIcon from "../../../images/icon-heart.png";
import HelpIcon from "../../../images/icon-help.png";
import MusicIcon from "../../../images/icon-music.png";
import LanguageIcon from "../../../images/icon-language.png";
import SearchIcon from "../../../images/icon-search-brand.png";
import PollIcon from "../../../images/icon-poll.png";
import AccountHeader from "./components/AccountHeader";
import Button from "../../basic/Button";
import { useModal } from "../../../hooks/useModal";
import { useTerms } from "../../../hooks/useTerms";

export default function Menu() {
  const { i18n } = useLanguage();
  const { nameseo, radioid } = useLocal();
  const { logout } = useUser();
  const { openModal: openHelpModal } = useModal(HELP_MODAL);
  const { openModal: openSuggestModal } = useModal(SUGGEST_MODAL);
  const { openModal: openPollModal } = useModal(POLL_MODAL);
  const { ready: termsReady, userExtra } = useTerms();

  const handleOpenHelp = () => {
    openHelpModal();
  };

  const handleOpenSuggest = () => {
    openSuggestModal();
  };

  const handleOpenPoll = () => {
    if (radioid && nameseo && termsReady) {
      openPollModal({ userExtra, radioid, nameseo });
    }
  };

  return (
    <div className="menu">
      <AccountHeader
        back={
          typeof nameseo === "string" && nameseo !== ""
            ? `/sonicbox/${nameseo}`
            : "/sonicboxes"
        }
      />
      <ul>
        <li>
          <Link to={`/${PROFILE_SECTION_PROFILE}`} className="menu-link">
            <ProfileButton />
          </Link>
        </li>
        <li>
          <Link to={`/${PROFILE_SECTION_CREDITS}`} className="menu-link">
            <PointsBadge />
            <T>profile.Credits</T>
          </Link>
        </li>
        <li>
          <Link to={`/${PROFILE_SECTION_FAVORITES}`} className="menu-link">
            <img
              src={FavoritesIcon}
              alt={i18n.t("profile.Favorites")}
              className="icon"
            />
            <T>profile.Favorites</T>
          </Link>
        </li>
        <li>
          <button type="button" onClick={handleOpenHelp} className="menu-link">
            <img src={HelpIcon} alt={i18n.t("profile.Help")} className="icon" />
            <T>profile.Help</T>
          </button>
        </li>
        {nameseo && userExtra && termsReady && (
          <li>
            <button
              type="button"
              onClick={handleOpenPoll}
              className="menu-link"
            >
              <img
                src={PollIcon}
                alt={i18n.t("profile.Help")}
                className="icon"
              />
              <T>poll.menu_link</T>
            </button>
          </li>
        )}
        {!nameseo ? (
          <li>
            <Link to={`/sonicboxes`} className="menu-link">
              <img
                src={SearchIcon}
                alt={i18n.t("profile.Help")}
                className="icon"
              />
              <T>profile.goSonicboxes</T>
            </Link>
          </li>
        ) : (
          <li>
            <button
              type="button"
              onClick={handleOpenSuggest}
              className="menu-link"
            >
              <img
                src={MusicIcon}
                alt={i18n.t("profile.suggest_music")}
                className="icon"
              />
              <T>profile.suggest_music</T>
            </button>
          </li>
        )}
        <li>
          <div className="lang-switcher-menu-item">
            <img
              src={LanguageIcon}
              alt={i18n.t("profile.Language")}
              className="icon"
            />
            <T>profile.Language</T> <LangSwitcher />
          </div>
        </li>
        <li>
          <Button className="logout-button" onClick={logout}>
            <T>profile.Logout</T>
          </Button>
        </li>
      </ul>
    </div>
  );
}

/* eslint-disable no-undef */
export function submitRecaptcha(publicToken) {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(function () {
      grecaptcha
        .execute(publicToken, { action: "submit" })
        .then(function (token) {
          resolve(token);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}

import { useCallback, useEffect, useRef, useState } from "react";
import { useTerms } from "../../hooks/useTerms";
import Loader from "../basic/Loader";
import Modal from "../layout/Modal";
import Button from "../basic/Button";

import "../../styles/terms.scss";
import TermsFormGroup from "../TermsFormGroup";
import ClientTerms from "../clientsContent";
import DevPre from "../dev/DevPre";

export default function TermsAndConditionsModal({
  nameseo,
  isPeeking,
  setIsPeeking,
}) {
  const { extraQuery, data, isLoading, acceptTerms, form, contentFormKey } =
    useTerms(nameseo);
  const [ticked, setTicked] = useState(false);
  const [checked, setChecked] = useState({});
  const [pendingTerms, setPendingTerms] = useState(null);

  const handleAcceptTerms = useCallback(
    (_checked) => {
      console.log("accepting terms");
      acceptTerms.mutate(form || contentFormKey ? _checked : { tos: true });
    },
    [acceptTerms, form, contentFormKey]
  );

  const handleClosePeeking = () => {
    setIsPeeking(false);
  };

  const handleCheck = useCallback((event) => {
    console.log(event.target.name, event.target.checked, event.target.type);
    let validInput = null;
    switch (event.target.type) {
      case "date":
        const date = new Date(event.target.value);
        const _18YearsAgo = new Date();
        _18YearsAgo.setFullYear(_18YearsAgo.getFullYear() - 18);
        validInput =
          date <= _18YearsAgo
            ? { name: event.target.name, value: event.target.value }
            : null;
        console.log("18 years ago", _18YearsAgo, date, validInput);
        break;
      case "text":
        validInput =
          typeof event.target.value === "string" &&
          event.target.value.length > 0
            ? { name: event.target.name, value: event.target.value }
            : null;
        console.log(
          "text",
          typeof event.target.value === "string",
          event.target.value.length > 0
        );
        break;
      case "radio":
      case "checkbox":
        validInput = event.target.checked
          ? { name: event.target.name, value: true }
          : null;
        break;
      default:
        break;
    }

    if (validInput) {
      setChecked((_checked) => ({
        ..._checked,
        [validInput.name]: validInput.value,
      }));
    } else {
      setChecked((_checked) => {
        if (_checked[event.target.name]) {
          delete _checked[event.target.name];
        }
        return _checked;
      });
    }
  }, []);

  const checkAll = (_checked, _form) => {
    if (_form) {
      let acceptAll = true;
      _form.forEach(({ input }) => {
        if (input?.required) {
          if (!Object.keys(_checked).includes(input.name)) {
            acceptAll = false;
          }
        }
      });
      return acceptAll;
    }
  };

  const pendingTermsRef = useRef("");
  useEffect(() => {
    if (nameseo && nameseo !== "" && (form || contentFormKey)) {
      const pendingTerms = JSON.parse(
        window.localStorage.getItem(`pendingterms`)
      );
      if (pendingTerms && pendingTerms[nameseo]) {
        if (form) {
          const allChecked = checkAll(pendingTerms[nameseo], form);
          if (allChecked) {
            delete pendingTerms[nameseo];
            window.localStorage.setItem(
              `pendingterms`,
              JSON.stringify(pendingTerms)
            );
            handleAcceptTerms(pendingTerms[nameseo]);
          }
        } else if (contentFormKey) {
          const pendingTermsString = JSON.stringify(pendingTerms[nameseo]);
          if (pendingTermsString !== pendingTermsRef.current) {
            pendingTermsRef.current = pendingTermsString;
            setPendingTerms(pendingTerms[nameseo]);
          }
        }
      }
    }
  }, [nameseo, form, handleAcceptTerms, contentFormKey]);

  useEffect(() => {
    if (form) {
      setTicked(checkAll(checked, form));
    }
  }, [checked, form]);

  useEffect(() => {
    if (acceptTerms.isSuccess) {
      extraQuery.refetch();
    }
  }, [acceptTerms.isSuccess, extraQuery, extraQuery.refetch]);

  if (isLoading) return <Loader />;
  if (data) {
    return (
      <Modal disableClose={true} className="terms-modal">
        {contentFormKey ? (
          <ClientTerms
            contentKey={contentFormKey}
            setChecked={setChecked}
            onAccept={setTicked}
            isPeeking={isPeeking}
            style={{
              height:
                Math.max(20, Math.min(60, data.text.length * 0.08)) + "vh",
            }}
            nameseo={nameseo}
            pendingTerms={pendingTerms}
          />
        ) : form ? (
          <div
            className="terms-container"
            style={{
              height:
                Math.max(20, Math.min(60, data.text.length * 0.08)) + "vh",
            }}
          >
            {form.map((formGroup, index) => (
              <TermsFormGroup
                key={index}
                onChange={handleCheck}
                {...formGroup}
                isPeeking={isPeeking}
              />
            ))}
          </div>
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: data.text }}
            className="terms-container"
            style={{
              height:
                Math.max(20, Math.min(60, data.text.length * 0.08)) + "vh",
            }}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isPeeking ? (
            <>
              {contentFormKey && (
                <Button
                  onClick={() => handleAcceptTerms(checked)}
                  className="success"
                  disabled={!ticked}
                  style={{ marginLeft: 10 }}
                >
                  {acceptTerms?.isLoading ? <Loader /> : "Guardar cambios"}
                </Button>
              )}
              <Button
                onClick={handleClosePeeking}
                className="success"
                style={{ marginLeft: 10 }}
              >
                Cerrar
              </Button>
            </>
          ) : (
            <>
              {!form && !contentFormKey && (
                <input
                  type="checkbox"
                  checked={ticked}
                  onChange={(event) => setTicked((ticked) => !ticked)}
                  style={{ width: 30, height: 30 }}
                  disabled={!!form}
                />
              )}
              <Button
                onClick={() => handleAcceptTerms(checked)}
                className="success"
                disabled={!ticked}
                style={{ marginLeft: 10 }}
              >
                {acceptTerms?.isLoading ? <Loader /> : "Autorizar"}
              </Button>
            </>
          )}
        </div>
        <DevPre>{JSON.stringify(checked, null, " ")}</DevPre>
      </Modal>
    );
  }
  return null;
}

import React, { useMemo } from "react";
import { useUser } from "../../../../hooks/useApi";
import LoadableImage from "../../../basic/LoadableImage";
import Loader from "../../../basic/Loader";
import DevPre from "../../../dev/DevPre";
import "../styles/profile.scss";

const ProfileHeader = () => {
  const {
    userQuery,
    user,
    logged,
    uploadAvatar,
    uploadAvatarMutation,
    editUserMutation,
  } = useUser();

  const { name, avatar } =
    user !== null
      ? user
      : {
          name: "",
          email: "",
          avatar: null,
        };

  const firstLetters = useMemo(() => {
    const split = name?.split(" ") || [];
    return split.reduce((prev, current) => `${prev}${current[0]}`, "");
  }, [name]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    // console.log("upload file", file);
    uploadAvatar({ key: "image", file: file });
  };

  return (
    <div className="profile-header">
      {logged ? (
        userQuery.isLoading ? (
          <Loader />
        ) : userQuery.isError ? (
          <p style={{ color: "red" }}>{userQuery.error}</p>
        ) : (
          <>
            <div className="avatar-row">
              <label className="avatar-container" htmlFor="avatar">
                {avatar ? (
                  uploadAvatarMutation.isLoading ||
                  editUserMutation.isLoading ? (
                    <Loader />
                  ) : (
                    <LoadableImage
                      src={avatar}
                      alt={name}
                      altComponent={<span>{firstLetters}</span>}
                    />
                  )
                ) : (
                  <span>{firstLetters}</span>
                )}
                <input
                  type="file"
                  name="avatar"
                  id="avatar"
                  onChange={handleFileInputChange}
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 0,
                    height: 0,
                    overflow: "hidden",
                  }}
                />
              </label>
            </div>
            {user?.name && <h2>{user.name}</h2>}
            <p>
              <small>{user.email}</small>
            </p>
          </>
        )
      ) : (
        <DevPre style={{ color: "lime" }}>
          {JSON.stringify(user, null, " ")}
        </DevPre>
      )}
    </div>
  );
};
export default ProfileHeader;

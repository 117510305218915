import React from "react";
import FlipMove from "react-flip-move";

import { useNextSong } from "../../../../hooks/useApi";
import "../styles/next-playing.scss";
import Loader from "../../../basic/Loader";
import DevPre from "../../../dev/DevPre";
import Song from "./Song";
import { T } from "../../../../hooks/useLanguage";

const NextPlaying = (props) => {
  const { data, isLoading, isError, error } = useNextSong(props.radioid);

  return (
    <div className="next-playing">
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <DevPre>{error.message}</DevPre>
      ) : data ? (
        data.length > 0 ? (
          <>
            <FlipMove>
              {data.map((song) => (
                <Song
                  key={song.songid}
                  {...song}
                  radioid={props.radioid}
                  nameseo={props.nameseo}
                />
              ))}
            </FlipMove>
            {data.map((song) => (
              <DevPre key={song.songid}>
                {JSON.stringify(song, null, " ")}
              </DevPre>
            ))}
          </>
        ) : (
          <p className="empty-msg">
            <T>sonicbox.emptyqueue</T>
          </p>
        )
      ) : (
        <DevPre>No se que paso</DevPre>
      )}
    </div>
  );
};

export default NextPlaying;

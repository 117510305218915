import BBCMUsicTerms from "./BBCMuiscTerms";
import ParqueAraucoTerms from "./ParqueAraucoTerms";

export default function ClientTerms({ contentKey, ...props }) {
  switch (contentKey) {
    case "bbc-music-pubs":
    case "casper-radio":
      return <BBCMUsicTerms {...props} />;
    case "parque-arauco-social-music":
      return <ParqueAraucoTerms {...props} />;
    default:
      return null;
  }
}

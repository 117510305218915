import React, { forwardRef, useEffect, useRef, useState } from "react";
import InputText from "./InputText";

const LaggedInput = (props, ref) => {
  const [activeValue, setActiveValue] = useState(props.value);
  const valueRef = useRef(activeValue);

  useEffect(() => {
    valueRef.current = activeValue;
    setTimeout(() => {
      if (valueRef.current === activeValue) {
        props.onChange(valueRef.current);
      }
    }, 500);
  }, [activeValue, valueRef, props]);

  useEffect(() => {
    if (props.value !== valueRef.current) {
      // console.log("force set value", props.value);
      setActiveValue(props.value);
    }
  }, [props.value, valueRef]);

  return (
    <InputText
      ref={ref}
      {...props}
      onChange={setActiveValue}
      value={activeValue}
    />
  );
};

export default forwardRef(LaggedInput);

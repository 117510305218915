import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "@reach/router";

import { useUser } from "../../../../hooks/useApi";
import { useTerms } from "../../../../hooks/useTerms";

import Loader from "../../../basic/Loader";
// import FacebookButton from "../../../FacebookButton";
import GoogleButton from "../../../GoogleButton";

import "../../../../styles/index.scss";

import useLanguage, { LangSwitcher, T } from "../../../../hooks/useLanguage";
import TermsAndConditionsContent from "../../../TermsAndConditionsContent";
import Button from "../../../basic/Button";

import LanguageIcon from "../../../../images/icon-language.png";

export default function LoginButtons({ isLoading = false }) {
  const navigate = useNavigate();
  const { logged, loginRedirect } = useUser();
  const [enabled, setEnabled] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { i18n } = useLanguage();

  const radioNameseo =
    loginRedirect.indexOf("/sonicbox/") === 0
      ? loginRedirect.replace("/sonicbox/", "")
      : null;

  const terms = useTerms(radioNameseo);

  useEffect(() => {
    if (terms?.data && terms?.data?.status === "success") {
      setShowTerms(false); // TODO revisar si es necesario o no
    }
  }, [terms?.data]);

  const handleAcceptTerms = (accept) => {
    const redirectLogin = window.localStorage.getItem("redirectLogin");
    // console.log("redirectLogin", redirectLogin);
    // console.log("typeof redirectLogin", typeof redirectLogin);
    // console.log(
    //   'redirectLogin.indexOf("/sonicbox/")',
    //   redirectLogin.indexOf("/sonicbox/")
    // );
    // console.log("redirectLogin.length", redirectLogin.length);
    if (
      logged &&
      typeof redirectLogin === "string" &&
      redirectLogin.indexOf("/sonicbox/") === 0 &&
      redirectLogin.length > 10
    ) {
      const nameseo = redirectLogin.replace("/sonicbox/", "");
      console.log("nameseo", nameseo);
      console.log("accept", accept);
      if (accept) {
        window.localStorage.setItem(`terms-${nameseo}`, "accepted");
      } else {
        window.localStorage.removeItem(`terms-${nameseo}`);
      }
    }
    console.log("set enabled", accept ? "true" : "false");
    setEnabled(accept);
  };

  return (
    <div className="index-page">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {showTerms && radioNameseo && (
            <TermsAndConditionsContent
              nameseo={radioNameseo}
              style={{ marginTop: 40, marginBottom: 40 }}
              ticked={enabled}
              onAccept={handleAcceptTerms}
            />
          )}
          {/* <FacebookButton enabled={enabled || !showTerms} /> */}
          <GoogleButton enabled={enabled || !showTerms} />
          <Button
            onClick={() => navigate("/verify")}
            disabled={!enabled && showTerms}
            className="success"
          >
            <T>verify.button</T>
          </Button>
          <p>
            <Link to="/login" disabled={!enabled && showTerms}>
              <T>home.mustlogin</T>
            </Link>
          </p>
        </>
      )}
      <div className="lang-switcher-container">
        <img
          src={LanguageIcon}
          alt={i18n.t("profile.Language")}
          className="icon"
        />
        <T>home.language</T>
        <LangSwitcher />
      </div>
      <Link to="/privacy" className="privacy">
        <T>home.privacy</T>
      </Link>
    </div>
  );
}

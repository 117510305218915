import { useRef } from "react";
import Modal from "../layout/Modal";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { useNavigate } from "@reach/router";
import { BRANCH_OPTIONS } from "../../constants";
import { useMemo } from "react";
import { setCookie } from "../../helpers/cookies";

const SelectBranchModal = ({ nameseo }) => {
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const modalRef = useRef();

  const { branches, name } = useMemo(() => {
    if (nameseo && typeof BRANCH_OPTIONS[nameseo] !== "undefined") {
      return BRANCH_OPTIONS[nameseo];
    }
    return { branches: [], name: null };
  }, [nameseo]);

  const handleSelectBranch = (branch) => {
    setCookie(nameseo, branch, 1);
    navigate("/sonicbox/" + branch);
    closeModal();
  };

  if (!name) return null;

  return (
    <Modal
      className="modal-select-branch"
      ref={(r) => (modalRef.current = r)}
      onClose={() => handleSelectBranch(nameseo)}
    >
      <h2
        style={{ textAlign: "center", marginTop: 120 }}
      >{`¿En qué ${name} estás?`}</h2>
      {Object.keys(branches).map((branch) => (
        <Button
          key={branch}
          size="big"
          className="gradient"
          onClick={() => {
            handleSelectBranch(branch);
          }}
          style={{ marginBottom: 20 }}
        >
          {branches[branch]}
        </Button>
      ))}
    </Modal>
  );
};

export default SelectBranchModal;

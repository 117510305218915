import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";

import { getNameseoByRadioid, useUser } from "../../../hooks/useApi";

import { T } from "../../../hooks/useLanguage";

import DevPre from "../../dev/DevPre";
import SonicboxLogoPNG from "../../../images/sonicbox-logo.png";
import "../../../styles/index.scss";
import decryptCode from "../../../utils/decryptCode";
import { useMemo } from "react";
import LoginButtons from "../login/components/LoginButtons";

const Redeem = ({ code }) => {
  const { logged, loginRedirect, setLoginRedirect } = useUser();
  const [storedCodes, setStoredCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [radioNameseo, setRadioNameseo] = useState(null);

  const parsedCode = useMemo(() => {
    if (code === "code") {
      const paramC = new URLSearchParams(window.location.search).get("c");
      return decodeURIComponent(paramC);
    }
    return code;
  }, [code]);

  useEffect(() => {
    console.log("logged", logged, "radioNameseo", radioNameseo);
    if (!logged) {
      if (radioNameseo) {
        window.localStorage.setItem(
          "redirectLogin",
          `/sonicbox/${radioNameseo}`
        );
        setLoginRedirect(`/sonicbox/${radioNameseo}`);
      }
      setIsLoading(false);
    }
  }, [radioNameseo, logged, setLoginRedirect, setIsLoading]);

  useEffect(() => {
    const newCodes = JSON.parse(
      window.localStorage.getItem("sonicbox-codes") || "[]"
    );
    console.log(newCodes);
    if (newCodes.indexOf(parsedCode) > -1) {
      console.log("Code already saved");
    } else {
      newCodes.push(parsedCode);
      window.localStorage.setItem("sonicbox-codes", JSON.stringify(newCodes));
    }
    setStoredCodes(newCodes);
  }, [parsedCode]);

  useEffect(() => {
    if (logged) {
      setTimeout(async () => {
        if (storedCodes.length > 0) {
          // console.log(storedCodes);
          const firstCode = decryptCode(storedCodes[0]);
          // console.log("first code decrypted", firstCode);
          const splitted = firstCode.split("-");
          // console.log(splitted);
          if (splitted.length === 2) {
            const codeRadioId = splitted[1];
            // navigate(`/sonicbox/${codeRadioId}`);
            const nameseo = await getNameseoByRadioid(codeRadioId);
            // console.log("navigate to nameseo", nameseo);
            if (nameseo) {
              navigate(`/sonicbox/${nameseo}`);
            } else {
              navigate(`/sonicboxes`);
            }
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      }, 1000);
    }
  }, [parsedCode, logged, storedCodes, loginRedirect, setIsLoading]);

  useEffect(() => {
    (async () => {
      if (parsedCode) {
        const decrypted = decryptCode(parsedCode);
        // console.log("decrypted code ", code, decrypted);
        const splitted = decrypted.split("-");
        if (splitted.length > 1) {
          const codeRadioId = splitted[1];
          // console.log("decrypted codeRadioId", codeRadioId);
          const nameseo = await getNameseoByRadioid(codeRadioId);
          // console.log("decrypted nameseo", nameseo);
          setRadioNameseo(nameseo);
          window.localStorage.setItem(
            "pendinginform",
            JSON.stringify({ code: splitted[0], radioid: codeRadioId, nameseo })
          );
        }
      }
    })();
  }, [parsedCode]);

  return (
    <div className="container index-page">
      <img src={SonicboxLogoPNG} alt="Sonicbox logo" className="page-logo" />
      <p>
        <T>home.welcome</T>
      </p>
      <DevPre>
        {typeof logged} - {logged ? "logged" : "not logged"}
      </DevPre>
      <h1>
        <T>home.redeemtitle</T>
      </h1>
      <LoginButtons isLoading={isLoading} />
    </div>
  );
};

export default Redeem;

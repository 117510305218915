import React, { useMemo } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-success.scss";
import Loader from "../basic/Loader";
import cleanSongTitle from "../../utils/cleanSongTitle";
import { useLocal } from "../../hooks/useApi";
import { T } from "../../hooks/useLanguage";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { useNavigate } from "@reach/router";

const VotedSuccessModal = ({ credits, songname, radioid, nameseo }) => {
  const { data: localData } = useLocal(radioid);
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const cleanSongname = useMemo(() => cleanSongTitle(songname), [songname]);

  const showCredits = useMemo(() => {
    return localData?.name.indexOf("El Corral") === -1 ? credits : 1;
  }, [localData, credits]);

  return (
    <Modal className="modal-success voted-success">
      {credits ? (
        <>
          <h2><T>votesuccess.title</T></h2>
          <p>
            <T count={showCredits} songname={cleanSongname}>
              votesuccess.message
            </T>
          </p>
          <Button
            className="big gradient"
            onClick={() => {
              closeModal();
              navigate(`/sonicbox/${nameseo}#playing`);
            }}
          >
            <T>votesuccess.seenowplaying</T>
          </Button>
          <Button className="accent bordered" onClick={closeModal}>
            <T>votesuccess.keepsearching</T>
          </Button>
        </>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default VotedSuccessModal;

import { useCode } from "../hooks/useCode";
import GiftSVG from "../images/gift.svg";

export default function PriceCodesTag() {
  const { priceCodes } = useCode();

  if (priceCodes?.length > 0) {
    return (
      <div
        style={{
          position: "fixed",
          top: 12,
          right: 12,
          zIndex: 9999,
          padding: "0.5rem 0.75rem",
          background: "#0e494f",
          borderRadius: 30,
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          fontSize: "1.25rem",
          fontWeight: "500",
        }}
      >
        <img src={GiftSVG} alt="gift" style={{ width: 24, height: 24 }} />
        {priceCodes.length}
      </div>
    );
  }
  return null;
}

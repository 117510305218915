import { useMemo } from "react";
import IconMusic from "../../images/icon-music.png";

const COVERS_FIRST = 17;
const COVERS_COUNT = 28;

export default function DefaultSongCover({ songid }) {
  const fakeCover = useMemo(() => {
    // console.log("DefaultSongCover", songid);

    if (typeof songid === "string" || typeof songid === "number") {
      const number = parseInt(songid, 10);
      if (number > 0) {
        const coverIndex = (number % COVERS_COUNT) + COVERS_FIRST;
        return `${process.env.PUBLIC_URL}/covers/${coverIndex}.jpg`;
      }
    }
  }, [songid]);

  return (
    <div className={`default-song-cover ${songid}`}>
      <img
        src={fakeCover ? fakeCover : IconMusic}
        alt="song"
        style={{ width: "100%", height: "100%", opacity: 1 }}
      />
    </div>
  );
}
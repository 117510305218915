import React, { useState } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-download-app.scss";
import * as rdd from "react-device-detect";
import AppStoreButton from "../AppStoreButton";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { DOWNLOAD_APP_REDEEM_MODAL } from "../../constants";

const DownloadAppRedeemModal = ({ collapsed = true }) => {
  const { closeModal } = useModal(DOWNLOAD_APP_REDEEM_MODAL);
  const [_collapsed, setCollapsed] = useState(collapsed);

  return (
    <Modal
      className={["modal-download-app", _collapsed && "collapsed"].join(" ")}
      onClose={() => setCollapsed(!_collapsed)}
      collapsed={_collapsed}
    >
      <h1 onClick={() => setCollapsed(false)}>Descárgate la App Sonicbox</h1>
      <p>
        Lleva Sonicbox adonde vayas
        <br />y sé siempre el centro de la fiesta
      </p>
      <p>
        Luego de descargarte la App, ve a "Cargar más créditos" y vuelve a
        scanear el código
      </p>
      <AppStoreButton platform={rdd.osName} />
      <Button onClick={closeModal} className="cancel-btn">
        Seguir en la web
      </Button>
    </Modal>
  );
};

export default DownloadAppRedeemModal;

import { GeoLocationProvider } from "../../../hooks/useGeoLocation";
import Sonicboxes from "./SonicboxesContent";

export default function LocationWrapper() {
  return (
    <GeoLocationProvider>
      <Sonicboxes />
    </GeoLocationProvider>
  );
}

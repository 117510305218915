import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { GOOGLE_CLIENT_ID } from "../constants";
import Button from "./basic/Button";
import Loader from "./basic/Loader";
import { useUser } from "../hooks/useApi";
import { T } from "../hooks/useLanguage";

export default function GoogleButton({ enabled = true }) {
  const { login, mutation } = useUser();

  const [errorMessage, setErrorMessage] = useState("");
  const responseGoogle = (response) => {
    console.log("Google login response", response);

    if (response && response.hasOwnProperty("accessToken")) {
      if (response.profileObj && response.profileObj.email !== undefined) {
        const dataToSend = {
          email: response.profileObj.email,
          name: `${response.profileObj.givenName} ${response.profileObj.familyName}`,
          oauth: response.accessToken,
          oauthid: response.googleId, //response.tokenId,
          avatar: response.profileObj.imageUrl,
          type: "google",
        };
        login(dataToSend);
      } else {
        console.log("setErrorMessage");
        setErrorMessage(<T>home.googleerror</T>);
      }
    } else {
      console.log("setErrorMessage");
      setErrorMessage(<T>home.googleerror</T>);
    }
  };

  useEffect(() => {
    console.log("errorMessage sideEffects", errorMessage);
    if (errorMessage !== "") {
      setTimeout(() => {
        setErrorMessage("");
      }, 3500);
    }
  }, [errorMessage]);

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <div>
          <Button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled || !enabled}
            className="google"
          >
            <div>
              {mutation?.isLoading ? <Loader /> : <T>home.logingoogle</T>}
            </div>
          </Button>
          {!renderProps.disabled && errorMessage !== "" ? (
            <div className="google-error-msg">{errorMessage}</div>
          ) : null}
        </div>
      )}
      buttonText="Ingresar con Google"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
}

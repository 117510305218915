import React, { useState, useMemo, useEffect } from "react";
import { useUser } from "../../../hooks/useApi";
import Loader from "../../basic/Loader";
import Button from "../../basic/Button";
import InputText from "../../basic/InputText";
import DevPre from "../../dev/DevPre";
import "./styles/style.scss";

import useLanguage, { LangSwitcher, T } from "../../../hooks/useLanguage";
import LanguageIcon from "../../../images/icon-language.png";

const EMAIL_REGEX = new RegExp(
  // eslint-disable-next-line no-useless-escape
  "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
);

const EmailVerification = function () {
  const {
    mutation,
    verifyEmail,
    verifyEmailMutation,
    notificationid,
    confirmloginMutation,
  } = useUser();
  const { isLoading, isError, error } = mutation;

  const [email, setEmail] = useState("");
  const [sentEmail, setSentEmail] = useState(email);

  const { i18n } = useLanguage();

  const emailIsValid = useMemo(() => EMAIL_REGEX.test(email), [email]);
  const [verificationCode, setVerificationCode] = useState("");

  const handleVerify = () => {
    verifyEmail(email);
    setSentEmail(email);
  };

  useEffect(() => {
    if (sentEmail !== email) {
      if (verifyEmailMutation.status !== "idle") {
        verifyEmailMutation.reset();
      }
      setSentEmail("");
    }
  }, [email, sentEmail, verifyEmailMutation]);

  useEffect(() => {
    return () => confirmloginMutation.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container verify">
      <h1>
        <T>verify.title</T>
      </h1>
      {verifyEmailMutation.status === "idle" && (
        <p>
          <T>verify.instructions</T>
        </p>
      )}
      <InputText
        label={<T>login.email</T>}
        type="text"
        name="username"
        onChange={setEmail}
        placeholder={i18n.t("verify.notvalidemailmessage")}
      />
      {email !== "" && !emailIsValid && <T>verify.notvalidemailmessage</T>}
      <Button
        onClick={handleVerify}
        block
        className="success"
        disabled={!emailIsValid}
      >
        {verifyEmailMutation?.isLoading ? (
          <Loader />
        ) : (
          <T>
            {verifyEmailMutation.isSuccess
              ? "verify.sendagain"
              : "verify.verify"}
          </T>
        )}
      </Button>
      {verifyEmailMutation.status === "success" && (
        <>
          <p>
            <T email={email}>verify.description</T>
          </p>
          <input
            type="text"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <Button
            onClick={() => confirmloginMutation.mutate(verificationCode)}
            className="success"
            loading={confirmloginMutation.isLoading}
          >
            {confirmloginMutation.isLoading ? <Loader /> : <T>verify.verify</T>}
          </Button>
          {confirmloginMutation.isError && (
            <div style={{color: "red", textAlign: "center", width: "100%"}}>Error: Código inválido</div>
          )}
          <p style={{ textAlign: "center" }}>
            <T>verify.waiting</T>
          </p>
        </>
      )}
      {verifyEmailMutation.isError && (
        <div className="error danger">
          {verifyEmailMutation.error?.message && (
            <>
              <small>{verifyEmailMutation.error.message}</small>
            </>
          )}
          <p>
            <T>verify.sendemailerror</T>
          </p>
        </div>
      )}
      <DevPre>
        <div>{verifyEmailMutation.status}</div>
        <div>{`email: "${email}"`}</div>
        <div>{`sentEmail: "${sentEmail}"`}</div>
        <div>{`notificationid: "${notificationid}"`}</div>
        <div>{`verificationCode: "${verificationCode}"`}</div>
      </DevPre>
      {isError ? (
        <p style={{ color: "red" }}>
          {error?.message ? error.message : "Unknown error"}
        </p>
      ) : isLoading ? (
        <>
          <Loader />
        </>
      ) : null}
      <div className="lang-switcher-container">
        <img
          src={LanguageIcon}
          alt={i18n.t("profile.Language")}
          className="icon"
        />
        <T>home.language</T>
        <LangSwitcher />
      </div>
    </div>
  );
};
export default EmailVerification;

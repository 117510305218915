import React, { useState } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-download-app.scss";
import * as rdd from "react-device-detect";
// import { isAndroid, isIOS, osName } from "react-device-detect";
import AppStoreButton from "../AppStoreButton";
import Button from "../basic/Button";
import { useModal } from "../../hooks/useModal";
import { DOWNLOAD_APP_MODAL } from "../../constants";
import useLanguage from "../../hooks/useLanguage";

// if (process.env.NODE_ENV === "development") {
//   rdd.isIOS = true;
//   rdd.osName = "iOS";
// }

const DownloadAppModal = ({ collapsed = true }) => {
  const { i18n } = useLanguage();
  const { closeModal } = useModal(DOWNLOAD_APP_MODAL);
  const [_collapsed, setCollapsed] = useState(collapsed);

  return (
    <Modal
      className={["modal-download-app", _collapsed && "collapsed"].join(" ")}
      onClose={() => setCollapsed(!_collapsed)}
      collapsed={_collapsed}
    >
      <h1 onClick={() => setCollapsed(false)}>{i18n.t("downloadapp.title")}</h1>
      <p>
        {i18n.t("downloadapp.subtitle1")}
        <br />
        {i18n.t("downloadapp.subtitle2")}
      </p>
      <AppStoreButton platform={rdd.osName} />
      <Button onClick={closeModal} className="cancel-btn">
        {i18n.t("downloadapp.remaininweb")}
      </Button>
    </Modal>
  );
};

export default DownloadAppModal;

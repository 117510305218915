export const VOTE_MODAL = "vote";
export const BUY_MODAL = "buy";
export const CODE_MODAL = "code";
export const ADD_CREDITS_MODAL = "add-credits";
export const ADD_CREDITS_SUCCESS_MODAL = "add-credits-success";
export const VOTED_SUCCESS_MODAL = "voted-success";
export const PRICE_MODAL = "price-modal";
export const CREDIT_CARD_MODAL = "credit-card";
export const ADD_SONG_TO_RADIO_MODAL = "add-song-radio-modal";
export const DOWNLOAD_APP_MODAL = "download-app-modal";
export const DOWNLOAD_APP_REDEEM_MODAL = "download-app-redeem-modal";
export const TERMS_AND_CONDITIONS_MODAL = "terms-and-conditions-modal";
export const HELP_MODAL = "help-modal";
export const SUGGEST_MODAL = "suggest-modal";
export const SELECT_BRANCH_MODAL = "select-branch-modal";
export const POLL_MODAL = "poll-modal";

export const PROFILE_SECTION_MENU = "menu";
export const PROFILE_SECTION_ORDERS = "orders";
export const PROFILE_SECTION_PROFILE = "profile";
export const PROFILE_SECTION_FAVORITES = "favs";
export const PROFILE_SECTION_CREDITS = "credits";
export const PROFILE_SECTION_SCANNER = "scanner";
export const PROFILE_SECTION_HELP = "help";

export const IDLE_WAITING_TIME = 25000;

export const MAX_VOTING_POINTS = 3;

export const PLATFORM_APPLE = "iOS";
export const PLATFORM_ANDROID = "Android";

export const FACEBOOK_APP_ID = "451636488878156";
export const GOOGLE_CLIENT_ID =
  "888560076929-57lj3lo26rc1b6phn0c3du9oi17odh16.apps.googleusercontent.com";

export const IOS_STORE_URL =
  "https://apps.apple.com/uy/app/sonicbox/id1499419036";
export const ANDROID_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.kicknerds.sonicbox";

export const BRANCH_OPTIONS = {
  // "papacho-s-radio": {
  //   branches: {
  //     "papacho-s-salaverry": "Papacho's Salaverry",
  //     "papacho-s-radio": "Papacho's Jockey Plaza",
  //     "papacho-s-cusco-radio": "Papacho's Cusco Radio",
  //   },
  //   name: "Papacho's",
  // },
};


// Gallagher's
const COD_990 = {
  942835: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  691432: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  428025: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  319400: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  216630: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  787068: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  283745: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  214633: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  273450: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  915446: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  880790: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  161818: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  429764: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  925374: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  521596: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  494440: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  943380: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  138816: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  467048: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  798066: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  848547: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  640136: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  222562: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  236685: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  850470: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  835557: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  270715: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  606256: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  876784: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  650711: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  300985: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  343970: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  691357: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  478726: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  861670: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  996020: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  617973: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  558758: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  979213: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  346230: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  272405: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  785322: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  861898: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  580625: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  579100: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  780276: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  959826: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  408962: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  654969: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  259671: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  916563: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  638511: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  453834: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  432584: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  655143: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  591230: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  793724: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  778157: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  872136: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  217278: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  716654: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  148943: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  393454: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  297559: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  865534: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  452080: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  797111: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  420768: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  537579: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  648783: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  362446: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  122903: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  424734: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  750328: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  522149: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  861170: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  905986: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  716878: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  748145: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  403517: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  680424: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  699281: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  175876: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  841090: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  444821: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  425693: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  603164: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  635433: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  924093: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  274758: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  470114: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  414201: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  374717: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  609747: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  496671: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  928817: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  796971: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  364632: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  737535: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  907402: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  953069: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  974953: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  574119: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  430783: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  942594: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  878974: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  774263: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  274852: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  678547: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  713174: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  288377: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  120794: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  692801: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  521759: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  759835: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  407903: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  260124: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  725933: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  773084: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  686767: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  783206: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  286566: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  131724: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  535879: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  909499: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  759828: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  281101: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  630843: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  590135: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  351093: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  911984: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  668803: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  754101: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  359599: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  746495: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  759842: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  474880: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  596458: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  174483: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  234232: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  876038: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  382644: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  509305: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  688298: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  190382: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  382627: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  783607: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  658223: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  798993: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  898851: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  328343: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  695496: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  160536: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  282712: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  746125: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  343554: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  744718: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  688128: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  544499: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  484061: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  133799: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  417102: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  330750: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  802639: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  886490: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  708529: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  812698: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  233712: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  233452: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  580622: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  823006: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  837851: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  364804: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  969954: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  631990: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  664352: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  204282: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  923045: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  310752: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  764312: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  460630: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  708943: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  643817: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  802397: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  894866: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  855290: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  423406: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  135960: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  778217: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  543817: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  779407: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  158584: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  728390: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  176074: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  993585: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  517906: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  412584: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  627338: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  996127: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  696946: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  985287: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  814617: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  566152: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  563372: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  174974: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  857862: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  265556: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  388672: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  721191: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  290107: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  766474: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  162552: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  608062: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  411470: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  105743: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  867423: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  505047: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  919558: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  766753: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  428958: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  858037: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  770639: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  334632: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  561760: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  351679: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  320977: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  680621: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  570900: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  391447: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  108700: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  260727: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  936696: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  381189: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  410932: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  519894: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  934528: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  916405: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  471155: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  394986: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  984890: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  367468: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  276693: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  613925: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  200935: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  366815: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  538413: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  753780: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  951369: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  826050: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  272549: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  239921: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  983679: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  549213: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  887678: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  595877: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  820941: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  409481: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  221878: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  702163: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  797801: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  486636: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  805334: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  140363: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  900814: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  560389: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  315104: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  238411: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  719797: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  589091: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  628224: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  403487: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  310194: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  526305: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  992881: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  982880: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  774784: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  204795: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  581155: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  178955: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  675851: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  383571: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  709168: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  982598: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  785069: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  952808: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  529143: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  947767: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  601557: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  274265: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  204315: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  379727: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  119616: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  525692: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  825738: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  624567: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  599015: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  772928: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  530382: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  445035: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  322592: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  219678: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  610769: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  319799: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  597502: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  974773: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  571063: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  785872: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  212634: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  124458: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  401296: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  765110: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  629178: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  104573: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  912883: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  978134: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  995849: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  466764: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  251458: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  704294: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  842324: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  726434: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  887582: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  324544: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  772232: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  841566: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  261298: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  119931: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  737533: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  180553: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  455775: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  249303: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  788010: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  899890: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  871974: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  862694: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  989017: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  690953: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  475661: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  955795: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  861000: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  560997: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  168437: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  357900: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  652410: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  439973: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  137142: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  765949: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  239071: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  591676: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  599254: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  769086: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  427680: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  782552: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  613566: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  781685: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  346907: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  627178: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  666269: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  228667: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  593591: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  801266: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  130021: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  951178: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  163763: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  744961: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  620605: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  460173: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  474064: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  866401: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  924222: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  563855: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  648365: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  505558: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  364115: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  517034: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  515027: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  843499: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  129357: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  810347: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  391161: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  914822: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  138251: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  462836: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  174777: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  417298: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  924202: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  707664: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  288277: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  604980: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  881860: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  394903: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  426277: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  371362: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  450045: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  610864: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  121204: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  259584: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  196334: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  431357: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  631639: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  698500: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  702208: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  627005: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  954370: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  949717: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  896931: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  813139: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  993533: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  479192: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  503371: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  648211: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  489365: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  614628: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  679427: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  139874: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  856946: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  770391: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  599813: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  498060: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  241371: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  875292: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  102394: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  137095: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  645536: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  109567: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  676367: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  735385: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  256926: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  411718: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  498284: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  605345: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  183365: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  676656: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  588689: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  176607: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  155779: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  473307: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  269632: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  968352: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  940760: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  611152: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  433307: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  552647: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  176296: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  304805: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  699699: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  153769: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  988175: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  439602: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  797699: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  260284: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  451817: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  944142: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  845250: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  984444: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  173786: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  259581: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  383479: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  778260: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  367607: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  350290: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  533496: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  209880: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  791787: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  177134: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  962174: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  582692: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  221735: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  913657: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  417205: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  416449: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  987152: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  858536: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  184712: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  664814: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  731623: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  312733: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  179989: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  778922: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  814187: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  271326: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  680754: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  266857: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  866774: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  917014: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  531686: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  930141: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  889758: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  698531: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  478452: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  641885: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  179213: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  278732: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  511665: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  731077: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  382883: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  760702: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  811762: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  600046: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  126272: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  706261: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  202085: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  425256: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  457908: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  898130: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  877423: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  769057: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  895392: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  173494: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  255702: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  305755: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  799853: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  129028: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  725571: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  720031: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  401648: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  831407: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  740326: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  333916: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  750135: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  381790: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  144509: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  722933: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  697108: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  629231: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  251418: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  919960: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  478565: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  311923: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  649109: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  191574: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  679571: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  238343: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  708369: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  378552: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  646279: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  617765: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  192825: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  338940: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  770477: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  965870: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  663677: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  450070: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  975424: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  571760: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  376474: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  451060: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  265985: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  931149: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  998034: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  846688: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  459916: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  693063: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  504374: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  484071: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  570704: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  963982: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  956587: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  221664: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  207209: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  813846: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  894829: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  312938: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  543504: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  288969: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  722393: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  652346: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  903202: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  234546: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  243806: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  328500: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  518649: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  500064: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  151334: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  201681: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  766215: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  159096: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  488570: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  617742: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  621430: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  350576: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  532593: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  408711: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  314197: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  228828: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  474125: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  459222: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  535104: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  198445: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  268086: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  835411: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  668311: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  404319: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  961752: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  279085: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  524299: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  447472: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  262235: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  742417: {
    radioid: 990,
    radioname: "Gallagher's",
  },
  573564: {
    radioid: 990,
    radioname: "Gallagher's",
  },
};

// Garrison Music
const COD_993 = {
  529673: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  849863: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  632278: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  272968: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  154271: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  922325: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  227024: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  809461: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  153343: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  288160: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  869284: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  365806: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  342232: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  698560: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  620653: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  645867: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  263752: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  301531: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  466738: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  538699: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  633253: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  277457: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  423701: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  675496: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  388865: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  826944: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  228412: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  552809: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  954109: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  806497: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  804962: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  692918: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  362084: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  957763: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  300904: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  812588: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  479479: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  705483: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  252982: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  846976: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  412046: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  738968: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  304988: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  811850: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  477789: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  860453: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  878542: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  455647: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  502481: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  841440: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  335904: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  334524: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  282715: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  974838: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  688129: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  218307: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  307103: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  158286: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  539341: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  660952: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  218886: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  114074: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  767337: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  480733: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  250878: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  761816: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  916001: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  551444: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  238684: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  828432: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  975530: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  180194: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  465711: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  637636: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  147482: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  301816: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  378496: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  349484: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  106545: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  571258: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  622870: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  763988: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  739507: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  413877: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  450966: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  110274: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  925984: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  199798: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  747881: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  399688: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  808495: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  144272: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  434301: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  426109: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  418810: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  942535: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  982353: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  521159: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  419214: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  521669: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  493091: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  894922: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  179412: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  735863: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  788602: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  660111: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  150604: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  610223: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  422570: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  678054: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  701775: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  307480: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  537587: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  239940: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  830733: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  568573: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  553008: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  313029: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  613620: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  921590: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  493702: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  928094: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  679362: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  153211: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  921025: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  954658: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  944859: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  506386: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  880991: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  425651: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  722552: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  184765: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  764219: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  378873: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  631620: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  929469: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  734335: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  473918: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  197585: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  645570: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  647519: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  553103: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  368109: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  749068: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  609418: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  470934: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  230240: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  789780: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  922234: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  526943: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  687324: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  840905: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  363746: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  161981: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  870361: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  941763: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  620232: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  935211: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  445883: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  895936: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  987419: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  269388: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  118980: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  711335: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  626870: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  194498: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  372249: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  733073: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  436862: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  281297: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  473185: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  387276: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  612330: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  634107: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  653175: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  925906: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  596699: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  814568: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  112581: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  253381: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  706059: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  890384: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  427353: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  391970: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  483992: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  430337: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  168976: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  733255: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  773271: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  463684: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  626352: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  695719: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  282736: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  696989: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  651806: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  439577: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  280202: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  954601: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  861622: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  219092: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  936865: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  419915: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  682640: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  472792: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  450923: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  170277: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  551755: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  365069: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  410725: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  201919: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  517456: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  265489: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  770305: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  725016: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  164456: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  548030: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  227413: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  445827: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  224893: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  634466: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  587826: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  648398: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  143705: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  550287: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  729585: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  431269: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  483375: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  986007: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  937368: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  628015: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  231051: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  210847: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  116617: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  990113: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  915577: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  797647: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  424807: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  309488: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  990548: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  685396: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  750406: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  902418: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  988414: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  934851: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  349476: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  188863: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  744631: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  217888: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  540611: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  493676: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  554477: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  862228: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  228385: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  674551: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  129183: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  511108: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  913320: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  366728: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  869857: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  788272: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  687370: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  916444: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  273214: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  334474: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  481912: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  811156: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  398972: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  561778: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  408137: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  100955: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  285845: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  486342: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  948319: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  800658: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  356846: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  314713: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  943256: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  266594: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  322806: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  985065: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  417305: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  369511: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  663214: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  116117: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  330843: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  918899: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  534163: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  418224: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  538681: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  955431: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  161497: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  533439: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  381221: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  894544: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  205319: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  176703: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  667056: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  955544: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  750139: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  915506: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  826870: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  143112: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  955822: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  946133: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  644538: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  188626: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  425865: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  769180: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  582666: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  920038: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  781508: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  489050: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  716356: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  586274: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  917029: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  163251: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  551047: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  512297: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  982719: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  587682: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  611771: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  466850: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  813224: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  474844: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  999639: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  170641: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  293075: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  527459: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  957139: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  535527: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  522328: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  846693: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  207053: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  317257: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  362568: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  850830: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  201524: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  874849: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  763763: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  963302: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  988144: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  973503: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  389759: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  686276: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  533020: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  872338: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  660560: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  307495: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  171268: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  964270: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  192193: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  445566: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  342699: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  858066: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  889269: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  976395: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  337246: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  760550: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  335600: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  678605: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  408417: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  849911: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  446137: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  293644: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  190365: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  485341: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  499847: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  645282: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  300467: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  361372: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  686711: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  707837: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  423332: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  717271: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  884361: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  139721: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  496206: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  343534: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  104488: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  145339: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  877279: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  439221: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  511798: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  166497: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  217634: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  407165: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  973926: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  680394: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  861449: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  239419: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  268751: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  772678: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  977455: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  517547: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  569327: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  768102: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  436914: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  911412: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  437660: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  705685: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  582621: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  356856: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  470020: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  829577: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  559623: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  635723: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  350372: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  809179: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  802032: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  358061: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  211936: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  204917: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  117067: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  725555: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  371046: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  511480: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  247390: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  892235: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  667293: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  512807: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  167389: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  960449: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  235705: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  505797: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  567816: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  554436: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  703129: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  225733: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  124090: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  850400: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  406171: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  409894: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  849036: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  445024: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  491840: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  169449: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  611740: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  210143: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  309338: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  121892: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  625460: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  385184: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  433438: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  423481: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  192866: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  414040: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  431137: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  767279: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  352159: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  574685: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  587410: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  943172: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  396331: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  635474: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  985079: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  109422: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  556516: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  131135: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  852273: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  434485: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  989639: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  903457: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  544513: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  683969: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  760481: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  500922: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  268760: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  650951: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  835225: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  979587: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  482905: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  943962: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  176114: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  961465: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  961899: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  513725: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  724028: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  723501: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  487756: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  972873: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  410590: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  160167: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  345741: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  544624: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  186622: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  392085: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  169892: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  338656: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  563627: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  667620: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  744666: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  476737: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  198274: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  414537: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  597022: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  682135: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  229393: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  339999: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  857685: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  375281: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  532051: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  332344: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  116822: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  609573: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  389690: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  500935: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  615855: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  479620: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  705191: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  702880: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  126641: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  518441: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  975534: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  710483: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  444297: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  983176: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  351645: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  320048: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  702459: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  194542: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  264229: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  489843: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  927480: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  110062: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  833578: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  388160: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  989691: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  221750: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  357575: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  663134: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  872286: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  802506: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  121582: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  190850: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  448675: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  321087: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  857470: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  634924: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  686963: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  283441: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  938913: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  773587: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  302921: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  421745: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  326789: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  864067: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  896087: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  544673: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  640329: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  637343: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  647167: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  965156: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  842947: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  505291: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  646886: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  355389: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  168312: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  507136: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  648337: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  291356: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  718848: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  816256: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  310053: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  121255: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  961088: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  544595: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  489660: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  645893: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  248795: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  411227: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  807291: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  368195: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  465139: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  632769: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  425550: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  147693: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  265495: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  869679: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  651024: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  929674: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  791172: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  233960: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  590528: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  372385: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  319976: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  245129: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  838530: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  379621: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  359576: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  495924: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  147486: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  934936: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  386570: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  510013: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  137340: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  730539: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  761066: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  626967: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  794191: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
  732815: {
    radioiid: 993,
    radioname: "Garrison Music",
  },
};
// Bigote
const COD_1008 = {
  489920: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  573831: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  712344: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  336065: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  854184: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  268004: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  284100: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  919813: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  703809: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  928497: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  946160: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  697808: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  709687: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  215567: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  465982: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  572213: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  711187: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  715501: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  501438: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  728041: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  186710: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  604665: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  221354: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  988198: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  621301: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  606261: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  232035: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  168904: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  569122: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  358964: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  537394: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  348963: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  734994: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  718360: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  432566: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  294243: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  253752: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  563981: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  944702: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  518964: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  751599: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  358623: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  186653: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  117046: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  470468: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  299051: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  288936: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  435555: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  372580: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  140429: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  690244: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  674356: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  996280: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  186355: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  573126: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  832750: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  701630: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  722331: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  481544: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  736190: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  239261: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  810319: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  560817: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  986041: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  206669: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  527811: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  426469: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  722266: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  373429: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  717581: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  711893: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  862191: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  679395: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  451196: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  142850: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  134281: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  887981: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  375168: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  356027: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  170328: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  782426: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  553624: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  201377: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  562700: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  980633: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  415186: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  931271: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  119927: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  712410: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  164086: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  783280: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  345193: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  246711: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  620916: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  475135: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  612984: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  823002: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  885969: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  641938: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  917101: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  829871: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  340547: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  530669: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  792645: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  743631: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  995593: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  921700: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  564668: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  568157: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  913214: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  881093: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  609416: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  154235: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  788066: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  323259: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  589897: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  203003: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  611359: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  505755: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  623608: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  194160: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  399286: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  269868: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  723711: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  152303: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  442343: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  639457: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  707193: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  448562: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  551231: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  924701: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  484209: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  276842: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  509820: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  866701: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  357787: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  403764: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  484035: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  696650: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  756053: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  487484: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  435911: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  516785: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  266010: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  451458: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  565644: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  115875: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  956117: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  301528: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  453044: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  166273: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  453092: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  311987: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  245028: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  677143: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  433808: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  277124: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  842358: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  710461: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  826548: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  591185: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  872282: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  403229: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  291676: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  807643: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  573529: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  233927: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  422638: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  608568: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  997960: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  331009: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  953945: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  947391: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  122080: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  534292: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  346626: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  150117: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  766877: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  347100: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  782419: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  443359: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  892281: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  257742: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  183770: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  562979: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  785316: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  722383: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  490403: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  435587: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  436537: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  547212: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  789300: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  173071: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  428124: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  901556: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  138119: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  468095: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  540633: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  352034: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  723403: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  843410: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  751349: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  724496: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  385261: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  394914: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  109224: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  270807: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  657436: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  864780: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  614855: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  219461: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  900068: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  552632: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  253423: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  820285: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  485437: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  852370: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  833229: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  348752: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  607378: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  483312: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  785102: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  407434: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  813591: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  607038: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  644671: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  711372: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  722424: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  818113: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  350410: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  189108: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  598022: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  892720: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  935184: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  214125: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  613821: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  573750: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  944087: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  599577: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  470525: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  228600: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  525210: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  967984: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  869942: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  921813: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  394747: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  676117: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  174918: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  680199: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  921666: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  396009: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  535845: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  208568: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  357026: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  823681: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  847533: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  683628: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  498223: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  911505: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  430287: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  453994: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  890991: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  390498: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  409103: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  373936: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  785485: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  957630: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  830734: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  534645: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  559175: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  481457: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  356158: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  775341: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  942559: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  265795: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  658406: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  416960: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  229465: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  936795: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  228437: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  360562: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  364061: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  376092: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  487563: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  781135: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  548375: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  417385: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  355353: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  656434: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  183624: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  945783: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  631539: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  791088: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  639060: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  426479: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  878493: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  661947: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  655218: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  136431: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  967875: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  319105: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  482330: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  558734: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  816930: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  124194: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  703470: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  139575: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  593705: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  169509: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  487373: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  505215: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  237437: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  629694: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  153890: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  565150: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  810221: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  459635: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  771871: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  676362: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  617836: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  832672: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  485523: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  268346: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  727772: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  885253: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  872154: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  464409: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  744119: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  340016: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  133432: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  851552: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  555601: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  173512: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  108525: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  110142: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  622774: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  788175: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  247250: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  454283: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  800684: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  402541: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  703245: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  598501: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  447353: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  404425: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  201707: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  716646: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  822891: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  519619: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  911576: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  234896: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  386153: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  589096: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  978789: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  698254: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  394905: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  857916: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  904813: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  156302: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  700710: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  697026: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  861960: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  574617: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  619315: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  313682: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  778663: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  471690: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  364618: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  431931: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  260447: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  781252: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  643632: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  466897: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  349762: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  976050: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  690365: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  256332: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  473341: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  481340: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  185627: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  374011: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  619388: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  509296: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  920040: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  690168: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  671297: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  126769: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  439300: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  326943: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  907631: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  691406: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  118874: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  107497: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  920731: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  839288: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  661024: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  914644: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  617246: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  158048: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  916160: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  733145: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  208962: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  136185: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  244874: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  305531: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  446879: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  284435: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  189007: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  716387: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  128839: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  678943: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  927793: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  127657: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  208764: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  789035: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  681321: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  166222: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  548884: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  541485: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  411485: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  633131: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  888330: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  572131: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  566381: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  764526: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  116385: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  680377: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  736961: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  609156: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  255154: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  870837: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  342105: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  739036: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  207775: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  631971: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  966927: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  702930: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  166161: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  468807: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  339669: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  585492: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  217920: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  357352: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  629979: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  444616: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  614359: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  688365: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  590965: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  961620: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  599010: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  186935: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  689503: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  856983: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  507048: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  295315: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  514886: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  706622: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  866351: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  344464: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  484665: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  283723: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  281458: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  521155: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  299118: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  615613: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  122778: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  700138: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  281178: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  158555: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  865749: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  346988: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  575716: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  271106: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  864965: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  831871: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  758144: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  791124: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  226243: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  186686: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  464652: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  769733: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  440569: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  939843: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  187307: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  956953: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  173343: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  706816: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  837840: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  279072: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  946619: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  551717: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  696902: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  310027: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  257750: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  270259: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  844889: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  628215: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  928204: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  631960: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  957304: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  611425: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  146881: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  487945: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  375921: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  138741: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  950999: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  569031: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  104751: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  920366: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  932622: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  350497: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  451846: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  812051: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  557954: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  472474: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  816614: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  217231: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  331314: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  194803: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  290375: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  168461: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  111593: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  729297: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  209754: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  365650: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  357330: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  877319: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  881256: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  108939: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  476191: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  693360: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  629891: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  600017: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  147635: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  120195: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  968203: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  234359: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  960413: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  697516: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  396574: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  932573: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  634544: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  164049: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  564642: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  897753: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  582040: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  574556: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  908066: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  441677: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  851605: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  703528: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  428908: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  262226: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  212440: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  320797: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  781005: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  565640: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  469465: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  124301: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  128655: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  117636: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  926723: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  373804: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  578553: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  906387: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  992875: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  849949: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  898029: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  145790: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  369780: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  763996: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  974802: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  204537: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  736872: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  898697: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  258928: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  714656: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  248339: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  124059: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  548603: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  489579: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  314211: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  132283: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  690871: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  107109: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  264898: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  203309: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  780757: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  461289: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  460156: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  654210: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  777580: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  119290: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  830281: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  231537: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  111113: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  445803: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  653210: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  952140: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  677620: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  502178: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  688349: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  482570: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  981678: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  491122: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  813595: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  182484: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  513211: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  657598: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  275290: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  937153: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
  379198: {
    radioid: 982,
    radioname: "Bigote Radio",
  },
};

export const PRICE_CODES = {
  ...COD_990,
  ...COD_993,
  ...COD_1008,
  575625: { radioid: 67, radioname: "Casper Radio" },
};
export const PRICE_CODE_STORE_TIME = 1000 * 60 * 60 * 12; // 12 hours
export const PRICE_CODE_REPEAT_TIME = 1000 * 60; // 1 minute

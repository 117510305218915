import { createContext, useContext, useEffect, useState } from "react";
import { useModal } from "./useModal";
import { POLL_MODAL } from "../constants";
import { useTerms } from "./useTerms";
import DevPre from "../components/dev/DevPre";

const POLL_INITIAL_DAYS_WAIT = 1; // time in days to wait before showing the poll
const POLL_INTERVAL_DAYS = 7; // time in days to wait before showing the poll again
const MS_PER_DAY = 1000 * 60 * 60 * 24;
const OPEN_MODAL_DELAY = 10; // time in seconds to delay opening the poll modal
const TICKER_STEP = 5; // time in seconds to set the ticker interval

const pollContext = createContext({
  lastPoll: null,
  closePoll: () => {},
});

export function PollProvider({ children }) {
  const [lastPoll, setLastPoll] = useState({
    date: new Date().setTime(0),
    count: 0,
  });

  useEffect(() => {
    // get last poll from local_storage
    const storedPoll = localStorage.getItem("lastPoll");
    // if last poll exists, set it to state
    if (storedPoll && typeof storedPoll === "string") {
      const splitted = storedPoll.split("|");
      if (splitted.length === 2) {
        const date = new Date();
        date.setTime(parseInt(splitted[0]) * 1000);
        const count = parseInt(splitted[1]);
        setLastPoll({ date, count });
      }
    } else {
      // if last poll doesn't exist set it to 6 days ago
      setLastPoll({ date: new Date().setTime(0), count: 0 });
    }
  }, []);

  useEffect(() => {
    if (lastPoll === null) return;
    // save last poll to local_storage
    const string = `${Math.floor(new Date(lastPoll.date).getTime() / 1000)}|${
      lastPoll.count
    }`;
    localStorage.setItem("lastPoll", string);
  }, [lastPoll]);

  const handleClosePoll = (didSubmit) => {
    if (didSubmit) {
      // set last poll to now
      setLastPoll((prev) => ({ date: new Date(), count: 0 }));
    } else {
      // set last poll to 6 days ago
      const pollDate = new Date();
      pollDate.setDate(
        pollDate.getDate() - (POLL_INTERVAL_DAYS - POLL_INITIAL_DAYS_WAIT)
      );
      setLastPoll((prev) => ({ date: pollDate, count: 0 }));
    }
  };

  const registerInteraction = () => {
    setLastPoll((prev) => ({ ...prev, count: prev.count + 1 }));
  };

  return (
    <pollContext.Provider
      value={{
        lastPoll: lastPoll,
        closePoll: handleClosePoll,
        registerInteraction,
      }}
    >
      <div style={{ position: "absolute", top: 10, zIndex: 99999 }}>
        <DevPre>
          {typeof lastPoll}
          {"\n"}
          {lastPoll ? JSON.stringify(lastPoll) : "null"}
        </DevPre>
      </div>
      {children}
    </pollContext.Provider>
  );
}

export default function usePoll(radioid, nameseo) {
  const { lastPoll, closePoll, registerInteraction } = useContext(pollContext);
  const { openModal } = useModal(POLL_MODAL);
  const { ready, userExtra } = useTerms(nameseo);
  const [ticker, setTicker] = useState(0);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    function tick() {
      setTicker((prev) => prev + TICKER_STEP);
    }
    const id = setInterval(tick, 1000 * TICKER_STEP);
    return () => clearInterval(id);
  }, [ticker]);

  useEffect(() => {
    if (ready && radioid && !modalShown && ticker > OPEN_MODAL_DELAY) {
      // if last poll is over 7 days ago, open poll modal
      if (
        new Date() - lastPoll.date > POLL_INTERVAL_DAYS * MS_PER_DAY &&
        lastPoll.count > 1
      ) {
        setTimeout(() => {
          openModal({ userExtra, radioid, nameseo });
          setModalShown(true);
        }, 7000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastPoll?.date,
    lastPoll?.count,
    userExtra,
    radioid,
    nameseo,
    modalShown,
    ticker,
  ]);

  return { lastPoll, closePoll, registerInteraction };
}

import { useUser } from "../../../hooks/useApi";
import { T } from "../../../hooks/useLanguage";
import PointsBadge from "../../basic/PointsBadge";
import AccountHeader from "./components/AccountHeader";
import IconQR from "../../../images/icon-qr.png";
import "./styles/credits.scss";
import { useNavigate } from "@reach/router";

const Credits = () => {
  const { user } = useUser();
  const points = user?.points || 0;
  const navigate = useNavigate();

  return (
    <>
      <AccountHeader back={"/menu"} title={<T count={0}>credit</T>} />
      <div className="credits">
        <PointsBadge size="7.5em" />
        <p className="credits-count-msg">
          <T count={points}>votemodal.creditsavailable</T>
        </p>
        <button
          type="button"
          className="scan-btn btn"
          onClick={() => navigate("/scanner")}
        >
          <img src={IconQR} alt="scan" className="icon" />
          <span>
            <T>votemodal.addcredits</T>
          </span>
        </button>
      </div>
    </>
  );
};

export default Credits;

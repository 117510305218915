import "../../styles/input-checkbox.scss";

export default function InputCheckbox({ input, checkbox }) {
  return (
    <div className="input-checkbox">
      <input {...input} />
      <input type="checkbox" {...checkbox} />
    </div>
  );
}

import { useCallback, useEffect, useState } from "react";
import {
  PRICE_CODE_REPEAT_TIME,
  PRICE_CODE_STORE_TIME,
  PRICE_CODES,
} from "../constants";
import { useMutation } from "react-query";
import { API_SONICOLA_URL, apiQueryClient, useUser } from "./useApi";
import axios from "axios";

export const useCode = () => {
  const { user, logged } = useUser();
  const token = logged ? user?.token : null;

  // Define the "colaCode" mutation
  apiQueryClient.setMutationDefaults("colaCode", {
    mutationFn: async (params) => {
      const dataToSend = {
        token: token,
        ccode: params.ccode,
      };
      const response = await axios.post(
        `${API_SONICOLA_URL}validateColaCode`,
        dataToSend
      );
      return response.data;
    },
    onMutate: async (variables) => {},
    onSuccess: (result, variables, context) => {
      // Replace optimistic todo in the user list with the result
      apiQueryClient.setQueryData("colaCode", (old) => result);
    },
    onError: (error, variables, context) => {
      console.log(
        "on colaCode error",
        error,
        error.response,
        variables,
        context
      );
      // Remove optimistic user from the user list
      apiQueryClient.setQueryData("colaCode", (old) => ({
        token: null,
        name: "",
      }));
    },
    retry: 1,
  });

  const mutation = useMutation("colaCode");

  const [priceCodes, setPriceCodes] = useState([]);

  useEffect(() => {
    function checkStoredPriceCodes() {
      const storedCodesStr = window.localStorage.getItem("priceCode");
      let storedCodes = [];
      if (storedCodesStr) {
        storedCodes = JSON.parse(storedCodesStr);
      }
      setPriceCodes(
        storedCodes.filter(
          (obj) => obj.date > new Date().getTime() - PRICE_CODE_STORE_TIME
        )
      );
    }
    const interval = setInterval(checkStoredPriceCodes, 1000);
    checkStoredPriceCodes();
    return () => clearInterval(interval);
  }, []);

  const sendCode = useCallback(
    async (
      params,
      successCallback = () => {},
      errorCallback = () => {},
      doneCallback = () => {}
    ) => {
      try {
        const codeResponse = await mutation.mutateAsync(params);
        if (codeResponse !== undefined) {
          successCallback(codeResponse);
        }

        const isPriceCode = PRICE_CODES.hasOwnProperty(params.ccode);
        if (isPriceCode) {
          console.log(params.ccode + " is Price Code " + isPriceCode);
          const storedCodesStr = await window.localStorage.getItem("priceCode");
          console.log("storedCodesStr", storedCodesStr);
          let storedCodes = [];
          if (storedCodesStr) {
            storedCodes = JSON.parse(storedCodesStr);
          }
          const newCodes = storedCodes.filter(
            (obj) => obj.date > new Date().getTime() - PRICE_CODE_STORE_TIME
          );
          const anyRepeatedCode = newCodes.find(
            (obj) =>
              obj.code === params.ccode &&
              obj.date > new Date().getTime() - PRICE_CODE_REPEAT_TIME
          );
          if (!anyRepeatedCode) {
            const radioid = PRICE_CODES[params.ccode].radioid;
            newCodes.push({
              code: params.ccode,
              date: new Date().getTime(),
              radioid: radioid,
            });
          }
          window.localStorage.setItem("priceCode", JSON.stringify(newCodes));
          setPriceCodes(newCodes);
        }
      } catch (error) {
        console.error("sendcode error", error);
        errorCallback(error);
      } finally {
        doneCallback();
      }
    },
    [mutation]
  );

  const codeData = mutation.data;

  const handleBurnPriceCode = (radioid) => {
    setPriceCodes((priceCodes) => {
      const newCodes = [...priceCodes];
      // const index = newCodes.findIndex(
      //   (obj) => parseInt(obj.radioid) === parseInt(radioid)
      // );
      if (newCodes.length > 0) {
        newCodes.pop();
      }
      window.localStorage.setItem("priceCode", JSON.stringify(newCodes));
      return newCodes;
    });
  };

  return {
    mutation: mutation,
    sendCode: sendCode,
    codeData: codeData,
    priceCodes: priceCodes,
    burnPriceCode: handleBurnPriceCode,
  };
};

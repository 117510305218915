import React, { useState, useEffect, useMemo, useRef } from "react";
import Modal from "../layout/Modal";
import "../../styles/modal-vote.scss";
import PointsInputWidget from "../PointsInputWidget";
import Button from "../basic/Button";
import { useLocal, useUser, useVote } from "../../hooks/useApi";
import { useCode } from "../../hooks/useCode";
import Loader from "../basic/Loader";
import { useModal } from "../../hooks/useModal";
import {
  MAX_VOTING_POINTS,
  PRICE_MODAL,
  VOTED_SUCCESS_MODAL,
} from "../../constants";
import DevPre from "../dev/DevPre";
import cleanSongTitle from "../../utils/cleanSongTitle";
import useLanguage, { T } from "../../hooks/useLanguage";
import LoadableImage from "../basic/LoadableImage";
import DefaultSongCover from "../basic/DefaultSongCover";
import { useNavigate } from "@reach/router";

const VoteModal = ({
  songid,
  songname,
  artist,
  cover,
  scid,
  radioid,
  nameseo,
}) => {
  const navigate = useNavigate();
  const { i18n, update } = useLanguage();
  const { openModal: openPriceModal } = useModal(PRICE_MODAL);
  const { openModal: openSuccessModal } = useModal(VOTED_SUCCESS_MODAL);
  const modalRef = useRef();

  const [credits, setCredits] = useState(1);
  const { user } = useUser();
  const { points } = user;
  const { data: localData } = useLocal(radioid);
  const { priceCodes } = useCode();

  const { mutation, vote, priceMutation } = useVote(radioid, nameseo);

  const handleVote = () => {
    let availableCredits = 0;
    if (!isNaN(credits) && credits > 0) {
      availableCredits += credits;
    }
    if (
      localData &&
      !isNaN(localData.fcreditsuser) &&
      localData.fcreditsuser > 0
    ) {
      availableCredits += localData.fcreditsuser;
    }
    if (availableCredits > 0) {
      if (credits <= availableCredits) {
        vote({ songid: songid, votes: credits, scid: scid, songname, artist });
      } else {
        alert(i18n.t("votemodal.notenoughcredits", { update }));
      }
    }
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      //alert(`Genial! le diste ${credits} puntos a ${songname}`);
      // console.log("mutation data", mutation.data);
      if (typeof mutation.data === "object" && mutation.data !== null) {
        if (mutation.data?.status === "success") {
          if (priceMutation.status === "idle") {
            // if (priceCodes.length > 0) {
            priceMutation.mutate({ radioid, songid });
            // } else {
            //   openSuccessModal({
            //     credits: credits,
            //     songname: songname,
            //     radioid: radioid,
            //     nameseo: nameseo,
            //   });
            // }
            mutation.reset();
          }
        } else if (mutation?.data?.msg) {
          alert(mutation.data.msg);
          mutation.reset();
        }
      } else {
        alert("Hubo un error al votar, intenta de nuevo");
        mutation.reset();
      }
      // console.log("open modal", modalRef.current);
    }
  }, [
    mutation,
    credits,
    songname,
    radioid,
    nameseo,
    priceMutation,
    songid,
    priceCodes,
    openSuccessModal,
  ]);

  useEffect(() => {
    if (priceMutation.isSuccess) {
      // open new window with price image
      const priceData = priceMutation?.data?.data;
      if (priceData?.price) {
        openPriceModal({
          credits: credits,
          songname: songname,
          radioid: radioid,
          nameseo: nameseo,
          msg: priceData?.msg,
          image: priceData?.price?.image,
          price: priceData?.price,
        });
      } else if (priceData?.image) {
        openPriceModal({
          credits: credits,
          songname: songname,
          radioid: radioid,
          nameseo: nameseo,
          msg: priceData?.msg,
          image: priceData?.image,
        });
      } else {
        openSuccessModal({
          credits: credits,
          songname: songname,
          radioid: radioid,
          nameseo: nameseo,
        });
      }
      priceMutation.reset();
    }
  }, [
    priceMutation.isSuccess,
    priceMutation?.data,
    openPriceModal,
    credits,
    songname,
    radioid,
    nameseo,
    priceMutation,
    openSuccessModal,
  ]);

  const errorMessage = mutation.isError && mutation.error.response.data.msg;

  const cleanSongname = useMemo(
    () => cleanSongTitle(songname, artist),
    [songname, artist]
  );

  const availableCredits = useMemo(() => {
    let availableCredits = 0;
    const intValPoints = parseInt(points);
    if (!isNaN(intValPoints) && intValPoints > 0) {
      availableCredits += intValPoints;
    }
    // if (
    //   localData &&
    //   !isNaN(localData.fcreditsuser) &&
    //   localData.fcreditsuser > 0
    // ) {
    //   availableCredits += localData.fcreditsuser;
    // }
    return availableCredits;
  }, [points]); // , localData]);

  const showPointsInputWidget = useMemo(() => {
    return (
      localData.name.indexOf("El Corral") === -1 &&
      localData.name.indexOf("Leños") === -1 &&
      localData.name.indexOf("Papa John") === -1 &&
      localData.name.indexOf("Gourmet") === -1
    );
  }, [localData?.name]);

  return (
    <Modal className="modal-vote" ref={(r) => (modalRef.current = r)}>
      <DevPre>{priceMutation.status}</DevPre>
      <div className="song modal-song-info">
        <LoadableImage
          src={cover}
          alt={songname}
          className="cover"
          altComponent={<DefaultSongCover />}
        />
        <div className="songname">{cleanSongname}</div>
        <div className="songartist">{artist}</div>
      </div>
      {showPointsInputWidget ? (
        <PointsInputWidget
          value={credits}
          onChange={setCredits}
          max={Math.min(MAX_VOTING_POINTS, points)}
        />
      ) : null}
      <Button
        size="big"
        className="gradient"
        onClick={handleVote}
        disabled={mutation.isLoading || availableCredits < credits}
      >
        {mutation.isLoading ? <Loader /> : <T>votemodal.vote</T>}
      </Button>
      {errorMessage ? <p className="error">{errorMessage}</p> : null}
      {availableCredits < credits && (
        <>
          <p className="credits-message">
            <T count={availableCredits}>votemodal.creditsavailable</T>
            {localData ? (
              <>
                <br />
                <span>
                  <T count={localData.fcreditsuser} radio={localData.name}>
                    votemodal.freecredits
                  </T>
                </span>
              </>
            ) : null}
          </p>
          <Button
            size="big"
            className="bordered brand"
            onClick={() => navigate("/scanner")}
          >
            <T>votemodal.entercode</T>
          </Button>
        </>
      )}
      <DevPre>{JSON.stringify(localData, null, " ")}</DevPre>
    </Modal>
  );
};

export default VoteModal;
